<ion-header>
  <ion-toolbar>
    <ion-title>Type your subject and message</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <categories-internal-details
    [internalNotif]="internalNotif"
      (cancel)="onCancel()"
      (clickSend)="sendInternalNotif($event)"
      (clearAllDetails)="onClearAllDetails()">
  </categories-internal-details>
</ion-content>
