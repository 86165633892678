<ion-header>
  <ion-toolbar>
    <ion-title>
      Select Your Emergency Type
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <categories-alert-type
    [isInternalAdmin]="getUserIsInternalAdmin()"
    (setSelectingCategory)="onSetIsSelectingCategory(false)"
    (selectSendNow)="selectSendNow()"
    (selectInternal)="selectInternal()"
    (selectLE)="selectLE($event)">
  </categories-alert-type>
</ion-content>
<!--
  *ngIf="isSelectingCategory; else enteringDetails"

  <ng-template  #enteringDetails>
    <categories-internal-details
      [internalNotif]="internalNotif"
      (setSelectingCategory)="onSetIsSelectingCategory(true)"
      (presentConfirmation)="onPresentConfirmation()"
      (clearAllDetails)="onClearAllDetails()">
    </categories-internal-details>
  </ng-template>
-->

<!--    
<ion-content>
  <section>
    <article>
      <ion-button class="send-now-button" (click)="buttonClickedSendNow()">Send Now</ion-button>
    </article>
  </section>
  <section>
    <article>
      <ion-label>Organization</ion-label>
    </article>
    <article>
      <ion-button class="all-campus-staff-button" (click)="buttonClickedInternal()">Admin</ion-button>
    </article>
  </section>
  <section>
    <article>
      <ion-label>Law Enforcement</ion-label>
    </article>
    <article>
      <ion-button class="active-shooter-button" (click)="buttonClickedLE()">Active Shooter</ion-button>
    </article>
    <article>
      <ion-button class="intruder-button" (click)="buttonClickedLE()">Intruder</ion-button>
    </article>
    <article>
      <ion-button class="medical-emergency-button" (click)="buttonClickedLE()">Medical Emergency</ion-button>
    </article>
  </section>
</ion-content>
-->
