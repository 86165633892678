import { Injectable } from '@angular/core';
//import * as moment from 'moment';
import {utcToZonedTime} from 'date-fns-tz';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  constructor() {}

  timeElapsedSinceDate(date: Date, maxDays: number): string {
    const timeSince = Date.now() - date.getTime();

    let days = Math.floor(timeSince /1000 / 60 /60 /24);
    let hours = Math.floor((timeSince /1000 / 60 /60) - (days * 24));
    let minutes = Math.floor((timeSince /1000 / 60) - (days * 24 * 60) - (hours * 60));

    return `${days > 0 ? days + 'd ' : '' }${hours > 0 ? hours + 'h ' : ''}${minutes}m`;
  }

  formatStartDate(date: Date) {
    const incoming = new Date(date);
    const today = new Date();
    const sameDay = incoming.getFullYear() === today.getFullYear() && incoming.getMonth() === today.getMonth() && incoming.getDate() === today.getDate();
    if(sameDay) {
      return incoming.toLocaleTimeString();
    }
    return incoming.toLocaleString();
  }

  formatMessageDate(date: Date) {
    return utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone).toLocaleString();
  }
}
