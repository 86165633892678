<ion-content (keyup.enter)="submitLogin()">
  <ion-grid>
    <ion-row>
      <ion-col align-self-center>
        <ion-img class="logo" src="../../assets/logo.png"></ion-img>
        <form #f="ngForm" (ngSubmit)="submitLogin()">
          <div class="input-wrapper">
            <ion-item *ngIf="isOrgUser" no-padding>
              <ion-icon [ngClass]="{ active: user.uniqueId }" name="inforce-user-icon"></ion-icon>
              <ion-input
                type="text"
                maxlength="64"
                placeholder="Unique ID"
                name="uniqueId"
                [(ngModel)]="user.uniqueId"
              ></ion-input>
            </ion-item>
            <ion-item no-padding>
              <ion-icon [ngClass]="{ active: user.username }" name="inforce-user-icon"></ion-icon>
              <ion-input
                type="text"
                maxlength="64"
                placeholder="Username"
                name="username"
                [(ngModel)]="user.username"
              ></ion-input>
            </ion-item>
            <ion-item no-padding>
              <ion-icon [ngClass]="{ active: user.password }" name="inforce-password-icon"></ion-icon>
              <ion-input
                type="password"
                maxlength="64"
                placeholder="Password"
                name="password"
                [(ngModel)]="user.password"
              ></ion-input>
            </ion-item>
          </div>
          <ion-button
            type="submit"
            [color]="formIsValid ? 'primary' : 'light-grey'"
            [disabled]="!formIsValid"
            expand="block"
          >
            Login
          </ion-button>
          <div class="forgot-password-holder">
            <span class="forgot-password-button" (click)="forgotPassword()">
              Forgot Password?
            </span>
          </div>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
