import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';

import { LoginService } from 'src/app/shared/services/login.service';
import { UserService } from 'src/app/shared/services/user.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
  failedLogoutMessage = 'We were unable to log you out at this time. Please try again.';

  constructor(
    private router: Router,
    private loginService: LoginService,
    private loadingService: LoadingService,
    private alertController: AlertController,
    private platform: Platform,
    private userService: UserService,
    private subscriptionService: SubscriptionService
  ) {}

  logout() {
    Promise.all([
      this.loginService.attemptLogout(),
      this.userService.clearUserData(),
      this.subscriptionService.clearLocalPubSubs()
    ])
      .then((values: any[]) => {
        // Both logout and clearing user data have failed
        if (!values[0] && !values[1]) {
          this.presentAlert('Error', this.failedLogoutMessage);
        } else {
          this.router.navigate(['/login']);
        }
      })
      .catch((err: any) => {
        this.presentAlert('Error', this.failedLogoutMessage);
      })
      .finally(() => {
        this.loadingService.dismiss();
      });
  }

  async presentConfirmation() {
    const alert = await this.alertController.create({
      message: 'Are you sure you want to logout?',
      header: 'Confirmation',
      buttons: [
        'Cancel',
        {
          text: 'Logout',
          handler: () => {
            this.loadingService.present();
            this.logout();
          }
        }
      ],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }

  async presentAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      message,
      header,
      buttons: ['OK'],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }
}
