import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {
  keyboardShowing = false;

  setKeyboardShowing(keyboardShowing: boolean): void {
    this.keyboardShowing = keyboardShowing;
  }
}
