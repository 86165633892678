<!--Temporary filler buttons, should be customizable to an extent-->
  <section>
    <article>
      <ion-label>Organization Alerts</ion-label>
    </article>
    <ng-container *ngIf="!isInternalAdmin; else InternalAdmin">
      <article>
        <ion-button class="all-campus-staff-button" (click)="buttonClickedInternal()">Administration</ion-button>
      </article>
    </ng-container>
    <ng-template #InternalAdmin>
     <article>
      <ion-button class="all-staff-button" (click)="buttonClickedInternal()">All Staff</ion-button>
      <!--<ion-button (click)="buttonClickedInternal()">Custom User Groups</ion-button>-->
     </article>
    </ng-template>
  </section>

  <section>
    <article>
      <ion-label>Law Enforcement Alerts</ion-label>
    </article>
    <article>
      <ion-button class="active-shooter-button" (click)="buttonClickedLE('Active Shooter')">Active Shooter</ion-button>
    </article>
    <article>
      <ion-button class="intruder-button" (click)="buttonClickedLE('Intruder')">Intruder</ion-button>
    </article>
    <article>
      <ion-button class="medical-emergency-button" (click)="buttonClickedLE('Medical Emergency')">Medical Emergency</ion-button>
    </article>
    <article>
      <ion-button class="send-now-button" (click)="buttonClickedSendNow()">Send Now</ion-button>
    </article>
  </section>