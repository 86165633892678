import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { School, AgencySchool } from '../models/school';
import { UserService } from './user.service';
import { UIAlertService } from './uiAlert.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {
  constructor(private http: HttpClient, private userService: UserService, private uiAlertService: UIAlertService) {}

  getSchool(id: number): Promise<School> {
    return new Promise((resolve, reject) => {
      this.userService.getAuthToken().then((token: string) => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        this.http.get(`${environment.baseURL}/schools/${id}`, { headers }).subscribe(
          (response: any) => {
            const school = {
              SchoolId: id,
              SchoolName: response.School[0].SchoolName,
              Address: response.School[0].Address,
              City: response.School[0].City,
              StateCode: response.School[0].StateCode,
              Zip: response.School[0].Zip
            };
            resolve(school);
          },
          failure => {
            if (failure.status === 401) {
              this.uiAlertService.presentExpiredTokenAlert();
            }
            reject(failure);
          }
        );
      });
    });
  }

  getSchools(userId: string, agencyId: string): Promise<AgencySchool[]> {
    return new Promise((resolve, reject) => {
      this.userService.getAuthToken().then((token: string) => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        this.http
          .get(`${environment.baseURL}/agency/schools?userId=${userId}&agencyId=${agencyId}`, { headers })
          .subscribe(
            (response: any) => {
              resolve(response.schools);
            },
            failure => {
              if (failure.status === 401) {
                this.uiAlertService.presentExpiredTokenAlert();
              }
              reject(failure);
            }
          );
      });
    });
  }
}
