import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { NewInternalNotifPage } from './new-internal-notif.page';
import { CategoriesInternalDetailsComponent } from 'src/app/components/categories-internal-details/categories-internal-details.component';
import { authGuard } from 'src/app/shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: NewInternalNotifPage,
    canActivate: [authGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    RouterModule.forChild(routes)
  ],
  declarations: [NewInternalNotifPage, CategoriesInternalDetailsComponent]
})
export class NewInternalNotifPageModule {}
