import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from 'src/app/shared/services/events.service';

import { Resource } from 'src/app/shared/models/resource';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { AlertDataService } from 'src/app/shared/services/alertData.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { ResourceService } from 'src/app/shared/services/resource.service';

import { PlatformType } from 'src/app/shared/enums';

import { environment } from 'src/environments/environment';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';


@Component({
  selector: 'resources',
  templateUrl: './resources.page.html',
  styleUrls: ['./resources.page.scss'],
})
export class ResourcesPage implements ViewWillEnter, ViewWillLeave {
  resources: Resource[] = [];
  refreshTime = 30000;
  fetchInterval = null;

  constructor(
    private router: Router,
    private resourceService: ResourceService,
    private loadingService: LoadingService,
    private chatService: ChatService,
    private alertDataService: AlertDataService,
    private subscriptionService: SubscriptionService,
    private events: EventService
  ) {}

  ionViewWillEnter() {
    this.loadingService.present();

    this.chatService.attemptConnection();

    this.events.tabsChangedRefresh(true);

    this.fetchResources().then((resources: Resource[]) => {
      this.loadingService.dismiss();
      this.resources = resources;
    });

    this.fetchInterval = setInterval(() => {
      this.fetchResources()
        .then((resources: Resource[]) => {
          this.resources = resources;
        })
        .catch((err: any) => {
          // Stop the timer
          if (err.status === 401) {
            clearInterval(this.fetchInterval);
          }
        });
    }, this.refreshTime);
  }

  ionViewWillLeave() {
    clearInterval(this.fetchInterval);
  }

  fetchResources(): Promise<Resource[]> {
    return new Promise((resolve, reject) => {
      this.resourceService
        .getUserResources()
        .then((fetchedResources: Resource[]) => {
          if (fetchedResources != null && fetchedResources.length != 0)
          {
            fetchedResources = fetchedResources.sort((a, b) => b.sortOrder - a.sortOrder);
          }
          resolve(fetchedResources);
        })
        .catch(err => reject(err));
    });
  }

  goToFile(filePath: string): void {
    if (environment.platformType == PlatformType.Browser) {
      window.open(filePath, '_blank');
    }
    else
    {
      window.open(filePath, '_self');
    }


  }
}
