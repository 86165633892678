import { Injectable } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class PushNotificationService {
	private readonly VAPID_PUBLIC_KEY = 'BC1nusUUe6duTaCZQ16YRJ7CMX2n3Hp0_CEJ8KkIdpdkLKHN-dHVOlWa81BRAmaAWTswwtETW8RMt7efJj4Ijj0';

	constructor(private swPush: SwPush, private http: HttpClient) {
		this.subscribeToNotifications();
		this.listenForMessages();
	}

	subscribeToNotifications() {
		if (this.swPush.isEnabled) {
			this.swPush.requestSubscription({
				serverPublicKey: this.VAPID_PUBLIC_KEY
			})
			.then(subscription => {
				// Send the subscription to the server
				this.http.post('/api/subscribe', subscription).subscribe();
			})
			.catch(err => console.error('Could not subscribe to notifications', err));
		}
	}

	listenForMessages() {
		navigator.serviceWorker.addEventListener('message', (event) => {
			if (event.data === 'playSound') {
				this.playAlertSound();
			}
		});
	}

	playAlertSound() {
		const audio = new Audio('/alert.mp3');
		audio.play().catch(error => console.error('Error playing sound:', error));
	}
}
