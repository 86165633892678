import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { NewReverseAlertPage } from './new-reverse-alert.page';
import { NewReverseAlertSchoolsComponent } from 'src/app/components/new-reverse-alert-schools/new-reverse-alert-schools.component';
import { NewReverseAlertDetailsComponent } from 'src/app/components/new-reverse-alert-details/new-reverse-alert-details.component';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from 'src/app/shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: NewReverseAlertPage,
    canActivate: [authGuard]
  }
];

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, RouterModule, RouterModule.forChild(routes)],
  declarations: [NewReverseAlertPage, NewReverseAlertSchoolsComponent, NewReverseAlertDetailsComponent]
})
export class NewReverseAlertPageModule {}
