import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

import { environment } from '../../../environments/environment';
import { Message } from '../models/message';
import { UserService } from './user.service';
import { Alert } from '../models/alert';
import { Location } from '../models/location';
import { ApplicationUserType } from '../enums';
import { DateService } from './date.service';
import { User } from '../models/user';
import { School } from '../models/school';
import { ReverseAlert } from '../models/reverseAlert';
import { UIAlertService } from './uiAlert.service';
import { XmppMessage } from '../models/xmppMessage';

@Injectable({
	providedIn: 'root'
})
export class AlertsService {
	constructor(
		private http: HttpClient,
		private userService: UserService,
		private dateService: DateService,
		private uiAlertService: UIAlertService
	) { }

	startNewAlert(user: User, location: Location, school: School, title: string): Promise<{ alertId: number; alertGUID: string }> {
		return new Promise((resolve, reject) => {
			const requestBody = {
				userId: user.uniqueId,
				location: location.location,
				locationId: location.locationId || 0,
				schoolId: school.SchoolId,
				title: title
			};

			this.userService
				.getAuthToken()
				.then((token: string) => {
					const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
					this.http
						.post<any>(`${environment.baseURL}/alerts/initiate`, requestBody, {
							headers
						})
						.subscribe({
							next: response => {
								if (response.responseSummary.statusCode === 0) {
									const alertId = parseInt(response.alertId, 10);
									const alertGUID = response.alertGUID;
									resolve({ alertId, alertGUID });
								}
								else {
									reject(response);
								}
							},
							error: failure => {
								if (failure.status === 401) {
									this.uiAlertService.presentExpiredTokenAlert();
								}
								reject(failure);
							}
						});
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	startNewInternalNotif(user: User, location: Location, school: School, title: string): Promise<{ alertId: number; alertGUID: string }> {
		return new Promise((resolve, reject) => {
			const requestBody = {
				userId: user.uniqueId,
				location: location.location,
				locationId: location.locationId || 0,
				schoolId: school.SchoolId,
				title: title
			};

			this.userService
				.getAuthToken()
				.then((token: string) => {
					const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
					this.http
						.post<any>(`${environment.baseURL}/alerts/initiate-internal-notif`, requestBody, {
							headers
						})
						.subscribe({
							next: response => {
								if (response.responseSummary.statusCode === 0) {
									const alertId = parseInt(response.alertId, 10);
									const alertGUID = response.alertGUID;
									resolve({ alertId, alertGUID });
								}
								else {
									reject(response);
								}
							},
							error: failure => {
								if (failure.status === 401) {
									this.uiAlertService.presentExpiredTokenAlert();
								}
								reject(failure);
							}
						});
				})
				.catch(err => {
					reject(err);
				});
		});
	}
	// Forwards an internal notification to a specific user group, or all staff
	forwardToUserGroup(alertId: number, title: String, permissionId: number, alertGUID: String): Promise<{ alertId: number; alertGUID: string }> {
		return new Promise((resolve, reject) => {
			const requestBody = {
				title: title,
				permissionId: permissionId,
				alertGUID: alertGUID
			}


			this.userService
				.getAuthToken()
				.then((token: string) => {
					const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
					this.http
						.post<any>(`${environment.baseURL}/alerts/${alertId}/forward-to-user-group`, requestBody, {
							headers
						})
						.subscribe({
							next: (response) => {
								if (response.responseSummary.statusCode === 0) {
									const alertId = parseInt(response.alertId, 10);
									const alertGUID = response.alertGUID;
									resolve({ alertId, alertGUID });
								}
								else {
									reject(response);
								}
							},
							error: (failure) => {
								if (failure.status === 401) {
									this.uiAlertService.presentExpiredTokenAlert();
								}
								reject(failure);
							}
						});
				})
				.catch(err => {
					reject(err);
				});
		});
	}
	// Forwards an internal notification to law enforcement
	forwardToLawEnforcement(alertId: number, title: String, alertGUID: String): Promise<{ alertId: number; alertGUID: string }> {
		return new Promise((resolve, reject) => {
			// To be filled
			const requestBody = {
				title: title,
				alertGUID: alertGUID
			};

			this.userService
				.getAuthToken()
				.then((token: string) => {
					const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
					this.http
						.post<any>(`${environment.baseURL}/alerts/${alertId}/forward-to-LE`, requestBody, {
							headers
						})
						.subscribe({
							next: (response) => {
								if (response.responseSummary.statusCode === 0) {
									const alertId = parseInt(response.alertId, 10);
									const alertGUID = response.alertGUID;
									resolve({ alertId, alertGUID });
								}
								else {
									reject(response);
								}
							},
							error: (failure) => {
								if (failure.status === 401) {
									this.uiAlertService.presentExpiredTokenAlert();
								}
								reject(failure);
							}
						});
				})
				.catch(err => {
					reject(err);
				});
		});
	}
	startNewReverseAlert(reverseAlert: ReverseAlert): Promise<{ alertId: number; alertGuid: string }> {
		return new Promise((resolve, reject) => {
			const schoolIds = reverseAlert.schoolIds.map((schoolId: number) => {
				return { schoolId };
			});
			const requestBody = {
				subject: reverseAlert.subject,
				message: reverseAlert.message,
				schoolIds
			};

			this.userService
				.getAuthToken()
				.then((token: string) => {
					const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
					this.http
						.post<any>(`${environment.baseURL}/alerts/initiate-reverse`, requestBody, {
							headers
						})
						.subscribe({
							next: (response) => {
								if (response.responseSummary.statusCode === 0) {
									const alertId = parseInt(response.alertId, 10);
									const alertGuid = response.alertGuid;
									resolve({ alertId, alertGuid });
								}
								else {
									reject(response);
								}
							},
							error: (failure) => {
								if (failure.status === 401) {
									this.uiAlertService.presentExpiredTokenAlert();
								}
								reject(failure);
							}
						})
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getAlertDetails(id: number) {
		return new Promise<Alert>((resolve, reject) => {
			this.userService.getAuthToken().then((token: string) => {
				const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
				this.http.get(`${environment.baseURL}/alerts/${id}/details`, { headers }).subscribe(
					(response: any) => {
						if (response.responseSummary.statusCode === 0 && response.alert.length > 0) {
							const {
								address,
								alertGuid,
								alertId,
								alertPreplans,
								city,
								createdDate,
								isReverseAlert,
								isInternal,
								message,
								subject,
								schoolId,
								schoolName,
								state,
								userId,
								zip
							} = response.alert[0];

							const userName =
								response.alert[0].userName !== null ? response.alert[0].userName : 'Unknown Alert Sender';

							const alertDetails: Alert = {
								address,
								alertGuid,
								alertId,
								alertPreplans,
								city,
								createdDate,
								isReverseAlert,
								isInternal,
								message,
								subject,
								schoolId,
								schoolName,
								state,
								userId,
								userName,
								zip
							};
							resolve(alertDetails);
						}
						else {
							reject(response.responseSummary.errorReason);
						}
					},
					failure => {
						if (failure.status === 401) {
							this.uiAlertService.presentExpiredTokenAlert();
						}
						reject(failure);
					}
				);
			});
		});
	}

	getChatHistory(alertId: number) {
		return new Promise<{ messages: Message[]; participants: any }>((resolve, reject) => {
			this.userService.getAuthToken().then((token: string) => {
				const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
				Promise.all([this.getAlertMessageHistory(alertId, headers), this.getAlertParticipants(alertId)])
					.then(values => {
						const messagesRaw = values[0];
						const participantsRaw = values[1];
						if (!messagesRaw || !participantsRaw) {
							reject(values);
						} else {
							const messagesArr = messagesRaw['messages'] as XmppMessage[];
							const participants = participantsRaw['participants'];

							let messages: XmppMessage[] = [];
							messages = _.uniqBy(messagesArr, 'messageId');
							messages = _.sortBy(messages, ['createdTime']);
							messages = _.filter(messages, 'message');

							let inforceMessages = _.map(messages, msg => {
								let participant = _.find(participants, function (part) {
									return part.userId === msg.userId;
								});

								if (participant == null) {
									participant = {
										userId: msg.userId,
										userName: msg.userName,
										userTypeId: ApplicationUserType.SchoolUser, // assume a missing participant is a school user
										isDispatcher: false // assume a missing participant is not a dispatcher
									};
								}

								let isSchoolUser = true;
								if (
									[
										ApplicationUserType.InforceAdmin,
										ApplicationUserType.AgencyAdmin,
										ApplicationUserType.AgencyUser,
										ApplicationUserType.Dispatcher
									].includes(participant.userTypeId)
								) {
									isSchoolUser = false;
								}

								return {
									alrtid: alertId,
									// workaround because they're not returning Z even though the dates are UTC
									createdTime: this.dateService.formatMessageDate(new Date(msg.sendDate + "Z")),
									msg: msg.message,
									msgId: msg.messageId,
									name: msg.userName,
									uid: msg.userId.toString(),
									actionType: null,
									msgUsrType: null,
									isSchoolUser
								} as Message;
							});

							resolve({ messages: inforceMessages, participants });
						}
					})
					.catch(failure => reject(failure));
			});
		});
	}

	getAlertMessageHistory(alertId: number, headers: any) {
		return new Promise((resolve, reject) => {
			this.http.get<any>(`${environment.baseURL}/alerts/${alertId}/messages`, { headers }).subscribe({
				next: response => {
					if (response.responseSummary.statusCode === 0) {
						resolve(response);
					}
					else {
						reject(response.responseSummary.errorReason);
					}
				},
				error: failure => {
					if (failure.status === 401) {
						this.uiAlertService.presentExpiredTokenAlert();
					}
					reject(failure);
				}
			});
		});
	}

	getAlertParticipants(alertId: number) {
		return new Promise((resolve, reject) => {
			this.userService.getAuthToken().then((token: string) => {
				const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
				this.http
					.get<any>(`${environment.baseURL}/alerts/${alertId}/participants`, {
						headers
					})
					.subscribe({
						next: response => {
							if (response.responseSummary.statusCode === 0) {
								resolve(response);
							}
							else {
								reject(response.responseSummary.errorReason);
							}
						},
						error: failure => {
							if (failure.status === 401) {
								this.uiAlertService.presentExpiredTokenAlert();
							}
							reject(failure);
						}
					});
			});
		});
	}

	getActiveAlertList() {
		return new Promise((resolve, reject) => {
			this.userService.getAuthToken().then((token: string) => {
				const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
				this.http
					.get<any>(`${environment.baseURL}/alerts`, {
						headers
					})
					.subscribe({
						next: response => {
							if (response.responseSummary.statusCode !== 0) {
								reject(response.responseSummary.errorReason);
							} else if (response.alerts.length === 0) {
								resolve(response.alerts);
							}

							response.alerts.forEach((alert: Alert) => {
								if (!alert.userName) {
									alert.userName = 'Unknown Alert Sender';
								}
							});

							resolve(response.alerts);
						},
						error: failure => {
							if (failure.status === 401) {
								this.uiAlertService.presentExpiredTokenAlert();
							}
							reject(failure);
						}
					});
			});
		});
	}

	joinAlert(alertId: number, userId: string) {
		return new Promise<any>((resolve, reject) => {
			this.userService
				.getAuthToken()
				.then((token: string) => {
					const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
					this.http
						.post<any>(
							`${environment.baseURL}/alerts/${alertId}/participants`,
							{ alertId: alertId, userId: userId },
							{
								headers
							}
						)
						.subscribe({
							next: () => resolve(true),
							error: failure => {
								if (failure.status === 401) {
									this.uiAlertService.presentExpiredTokenAlert();
								}
								reject(false);
							}
						});
				})
				.catch(() => {
					reject(false);
				});
		});
	}

	closeAlert(alertId: number) {
		return new Promise<boolean>((resolve, reject) => {
			this.userService.getAuthToken().then((token: string) => {
				const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

				this.http
					.post<any>(
						`${environment.baseURL}/alerts/${alertId}/terminate`,
						{},
						{
							headers
						}
					)
					.subscribe({
						next: () => resolve(true),
						error: failure => {
							if (failure.status === 401) {
								this.uiAlertService.presentExpiredTokenAlert();
							}
							reject(false);
						}
					});
			});
		});
	}
}
