import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ReverseAlert } from 'src/app/shared/models/reverseAlert';
import { computed } from 'mobx-angular';
import { KeyboardService } from 'src/app/shared/services/keyboard.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'new-reverse-alert-details',
  templateUrl: './new-reverse-alert-details.component.html',
  styleUrls: ['./new-reverse-alert-details.component.scss']
})
export class NewReverseAlertDetailsComponent implements OnInit {
  @Input() reverseAlert: ReverseAlert | null = null;
  @Output() setSelectingSchools = new EventEmitter<boolean>();
  @Output() presentConfirmation = new EventEmitter();
  @Output() clearAllDetails = new EventEmitter();

  isMobile: boolean = false;

  constructor(private keyboardService: KeyboardService,
    private platform: Platform) { }

  ngOnInit() {
    this.clearAllDetails.emit();
    this.isMobile = this.isRunningOnMobile();
  }

  isRunningOnMobile(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];

    const isMobileUserAgent = /Mobi|Android|iPhone|iPad|iPod/.test(userAgent);

    const platformCheck = this.platform.is('android') || this.platform.is('ios');

    return isMobileUserAgent || platformCheck;

  }

  @computed get canSendReverseAlert() {
    if (!this.reverseAlert) return '';
    return this.reverseAlert.subject.trim() && this.reverseAlert.message.trim();
  }

  @computed get keyboardShowing() {
    return this.keyboardService.keyboardShowing;
  }

  onFocus(): void {
    if (this.isMobile)
      this.keyboardService.setKeyboardShowing(true);
  }

  onBlur(): void {
    if (this.isMobile)
      this.keyboardService.setKeyboardShowing(false);
  }

  onSetSelectingSchools(): void {
    this.setSelectingSchools.emit(true);
  }

  onPresentConfirmation(): void {
    this.presentConfirmation.emit();
  }
}
