import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LocationsPage } from './locations.page';
import { authGuard } from 'src/app/shared/guards/auth.guard';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: LocationsPage,
    canActivate: [authGuard]
  }
];

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, RouterModule, RouterModule.forChild(routes)],
  declarations: [LocationsPage]
})
export class LocationsPageModule {}
