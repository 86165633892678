import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { AlertDataService } from './alertData.service';

@Injectable({
  providedIn: 'root'
})

// Resolves data when routing to page so that data is available when page loads.
//
// To add this service to a page, update app-routing.module to include 'resolve':
// {
//   path: <path>,
//   resolve: { <data name>: DataResolverService },
//   component: <component name>
// }
//
// Then import 'ActivatedRoute' from @angular/router on the page receiving data and access via:
// this.route.snapshot.data['<data name>'];
export class DataResolverService {
  constructor(private alertDataService: AlertDataService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get('alertId');
    return this.alertDataService.getData(parseInt(id, 10));
  }
}
