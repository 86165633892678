import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TabsPageRoutingModule } from './tabs.router.module';

import { TabsPage } from './tabs.page';
import { CategoriesPageModule } from '../categories/categories.module';
import { LocationsPageModule } from '../locations/locations.module';
import { AlertsPageModule } from '../alerts/alerts.module';
import { SettingsPageModule } from '../settings/settings.module';
import { AlertPageModule } from '../alert/alert.module';
import { NewAlertPageModule } from '../new-alert/new-alert.module';
import { NewReverseAlertPageModule } from '../new-reverse-alert/new-reverse-alert.module';
import { NewInternalNotifPageModule } from '../new-internal-notif/new-internal-notif.module';
import { ResourcesPageModule } from '../resources/resources.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TabsPageRoutingModule,
    CategoriesPageModule,
    LocationsPageModule,
    AlertsPageModule,
    SettingsPageModule,
    AlertPageModule,
    NewAlertPageModule,
    NewReverseAlertPageModule,
    NewInternalNotifPageModule,
    ResourcesPageModule
  ],
  declarations: [TabsPage]
})
export class TabsPageModule {}
