import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { CategoriesPage } from './categories.page';

import { CategoriesAlertTypeComponent } from 'src/app/components/categories-alert-type/categories-alert-type.component';
import { authGuard } from 'src/app/shared/guards/auth.guard';
//import { CategoriesInternalDetailsComponent } from 'src/app/components/categories-internal-details/categories-internal-details.component';


const routes: Routes = [
  {
    path: '',
    component: CategoriesPage,
    canActivate: [authGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    RouterModule.forChild(routes)
  ],
  declarations: [CategoriesPage, CategoriesAlertTypeComponent]
})
export class CategoriesPageModule {}
