<div
  [ngClass]="{
    'internal-notif': alert.isInternal,
    'reverse-alert': alert.isReverseAlert,
    'inforce-alert': !alert.isReverseAlert && !alert.isInternal
  }"
  class="alert"
  (click)="onGoToAlert()"
>
  <ng-container *ngIf="!alert.isReverseAlert; else reverseAlert">
    <ng-container *ngIf="!alert.isInternal; else internalNotif">
      <ion-icon name="inforce-warning-icon" color="danger"></ion-icon>
      <div class="alert-info">
        <div class="alert-type">IN FORCE911 ALERT</div>
        <p class="subject">{{ alert.subject }}</p>
        <p class="school-name">{{ alert.schoolName }}</p>
        <p class="school-address">{{ alert.address }}, {{ alert.city }}, {{ alert.state }} {{ alert.zip }}</p>
        <p class="origin">{{ alert.userName }}</p>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #reverseAlert>
    <ion-icon name="inforce-shield-icon"></ion-icon>
    <div class="alert-info">
      <div class="alert-type">REVERSE ALERT</div>
      <p class="origin">{{ alert.userName }}</p>
      <p class="subject">{{ alert.subject }}</p>
    </div>
  </ng-template>
  <ng-template #internalNotif>
    <ion-icon name="inforce-shield-icon"></ion-icon>
    <div class="alert-info">
      <div class="alert-type">INTERNAL NOTIFICATION</div>
      <p class="subject">{{ alert.subject }}</p>
      <p class="school-name">{{ alert.schoolName }}</p>
      <p class="school-address">{{ alert.address }}, {{ alert.city }}, {{ alert.state }} {{ alert.zip }}</p>
      <p class="origin">{{ alert.userName }}</p>
    </div>
  </ng-template>
  <div class="time-info">
    <div class="date-created"><span class="label">Started</span><br />{{ timeStarted }}</div>
    <div class="time-elapsed"><span class="label">Time Active</span><br />{{ timeSinceCreated }}</div>
  </div>
</div>
