export const eula = `FOR ALL BUSINESS, INDIVIDUALS, OR OTHER ENTITIES HAVING A RESIDENCE, OPERATIONS, OR
INCORPORATION IN THE UNITED STATES, PLEASE READ THE “BINDING ARBITRATION AND CLASS
ACTION WAIVER” SECTION BELOW, AS THIS SHALL AFFECT HOW ALL DISPUTES ARE RESOLVED.
PLEASE FURTHER ACCEPT THE TERMS BY CLICKING “ACCEPT.”

These license terms are an agreement (“Agreement”) between you and In Force Technology, Inc. (“In
Force”) (or any of its affiliates). The terms of this Agreement apply to the product named above and any
services or updates (except to the extent such services or updates are accompanied by new or additional
terms, in which case those different terms apply prospectively and do not alter your or In Force’s rights
relating to pre-updated product or services) that In Force shall provide from time to time. IF YOU
COMPLY WITH THESE LICENSE TERMS, YOU HAVE THE RIGHTS BELOW. BY USING THE PRODUCT, YOU
ACCEPT THESE TERMS.
1. INSTALLATION AND USE RIGHTS.
a) General. You may install and run one instance of the product on an appropriate device as
outlined in the instructions provided to you separately.
b) Subscription. Use of the product requires the payment of a fee and continuing subscription
fees. Your use of the product must comply with the existing terms for the service that apply to
you through your or your organization&#39;s subscription. You may lose access to the product or In
Force’s services: (i) if your or your organization’s subscription expires or is terminated, or (ii)
your license to this product is terminated by your organization or by In Force.
c) Third-Party Applications and Products. The product may include third-party applications and
products that are licensed to you under this agreement or under their own terms. License terms,
notices, and acknowledgements, if any, for the third-party applications and products will be
provided to you from time to time by In Force. Even if such applications and products are
governed by other agreements, the disclaimer, limitations on, and exclusions of damages below
also apply to the extent allowed by applicable law.
d) Privacy Terms. In Force’s privacy statement applies to your use of the product. You may
access the privacy statement using the links provided with the product, and as applicable,
through the In Force Privacy Policies provided to you in the In Force App Store. Features of the
product may rely on online resources. The use of those services (but not the product) is
governed by the privacy policies.
2. DATA COLLECTION.
The product may collect information about you and your use of the product. Such data may be retained
by In Force to improve its services and product. Some features in the product may enable collection of
data from users of your applications that access or use the product. You agree that In Force may retain
such data. In Force agrees to maintain such data in confidentiality using reasonable protections of such
data. To the extent that there is a data breach, your only remedy is detailed in the “Binding Arbitration
and Class Action Waiver” below.

3. PROCESSING OF PERSONAL DATA.
To the extent In Force is a processor or sub processor of personal data in connection with the product,
In Force makes the commitments in the European Union General Data Protection Regulation Terms of
the Online Services Terms to all customers effective 25 May 2018 ( See attachment 1 ).
4. SCOPE OF LICENSE.
The product is licensed, not sold. In Force reserves all other rights. Unless applicable law gives you more
rights despite this limitation, you will not (and have no right to):
a) work around any technical limitations in the product that only allow you to use it in certain
ways;
b) reverse engineer, decompile or disassemble the product;
c) copy, duplicate, or otherwise manipulate the product or any portion of the product;
d) remove, minimize, block, or modify any notices of In Force or its suppliers in the product;
e) use the product in any way that is against the law or to create or propagate malware; or
f) share, publish, distribute, or lend the product, provide the product as a stand-alone hosted
solution for others to use, or transfer the product or this agreement to any third party.
5. EXPORT RESTRICTIONS.
You must comply with all domestic and international export laws and regulations that apply to the
product, which include restrictions on destinations, end users, and end use. You must not export the
product for sale, license, or other any use.
6. USE LIMITATIONS
You must comply with all provisions of this Agreement. You agree not perform, or to cause any third
party to perform, any modifications, alterations, or other actions to change the product. You further
agree to operate the product in accordance with all instructions provided by In Force. Any violation of
this Section, or unauthorized use of the product, shall be deemed a material breach of this Agreement.
7. LEGAL COMPLIANCE.
You agree to comply with all laws and regulations relating to the use of the product or In Force services.
You agree to hold In Force harmless and indemnify In Force from and all legal liability through your
failure to comply with all laws and regulations relating to your use of the product or In Force services.
8. SUPPORT SERVICES.
In Force is not obligated under this agreement to provide any support services for the product. Any
support provided is “as is”, “with all faults”, and without warranty of any kind.

9. UPDATES.
The product may periodically check for updates, and download and install them for you. You may obtain
updates only from In Force or In Force’s authorized third-party providers. In Force may need to update
your system to provide you with updates from time to time. You agree to receive these automatic
updates without any additional notice. Updates may not include or support all existing product features,
services, or peripheral devices.
10. BINDING ARBITRATION AND CLASS ACTION WAIVER.
This Section applies if you live in (or, if a business, your principal place of business is in, is incorporated in
any jurisdiction in the United States, or is a foreign corporation registered to conduct business in any
jurisdiction in the United States) the United States. If you and In Force have a dispute, you and In Force
agree to try for 60 days to resolve it informally. If you and In Force do not resolve the dispute informally,
you and In Force agree to resolution of all matters described in Attachment 2 through binding
arbitration by way of the American Arbitration Association under the Federal Arbitration Act (“FAA”)
before a neutral arbitrator. You and In Force hereby agree to waive any and all rights to pursue this
matter before state and federal court no matter whether a right or remedy would exist. Furthermore, all
class action lawsuits, class-wide arbitrations, private attorney general actions, and any other proceeding
where someone acts in a representative capacity are not allowed. You and In Force also agree to waive
any right to join in any action even if such right exists under law or equity.
11. TERMINATION.
Without prejudice to any other rights, In Force may terminate this Agreement at any time due to failure
to comply with any terms or provisions. In such event, you must destroy all copies of the product and all
of its component parts.
12. ENTIRE AGREEMENT.
This agreement, which may provide for supplements, updates, or third-party applications, is the entire
agreement for the product.
13. APPLICABLE LAW AND PLACE TO RESOLVE DISPUTES.
You must use this product in accord with the laws of the state or jurisdiction where you live (or, if a
business, where your principal place of business is located). Nevertheless, you and In Force agree that
the laws of the Commonwealth of Massachusetts govern the interpretation of this Agreement, claims
for its breach, and all other claims (including consumer protection, unfair competition, and tort claims),
regardless of conflict of laws principles, except that the FAA governs all matters relating to arbitration
procedures. If U.S. federal jurisdiction exists, you and In Force consent to exclusive jurisdiction and
venue in the district of Massachusetts, Boston Division for all disputes heard in court (excluding
arbitration). If there is no federal jurisdiction, then you and In Force consent to exclusive jurisdiction and
venue in the Superior Court of Essex County, Massachusetts for all disputes heard in court (excluding
arbitration). You and In Force waive any and all arguments relating to the propriety of jurisdiction and
venue.
14. SEVERABILITY.

If any provision this Agreement is found to be unenforceable or void, then such provision will be severed
and shall not affect the enforceability of the remainder of the Agreement.
15. DISCLAIMER OF WARRANTY.
THE PRODUCT IS LICENSED “AS IS.” YOU BEAR THE RISK OF USING IT. IN FORCE GIVES NO EXPRESS
WARRANTIES, GUARANTEES, OR CONDITIONS. IN FORCE EXCLUDES ALL IMPLIED WARRANTIES,
INCLUDING MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. 14.
LIMITATION ON AND EXCLUSION OF DAMAGES. IF YOU HAVE ANY BASIS FOR RECOVERING DAMAGES
BEYOND THIS DISCLAIMER, YOU CAN RECOVER FROM IN FORCE AND ITS SUPPLIERS ONLY DIRECT
DAMAGES UP TO U.S. $10.00. YOU AGREE THAT YOU SHALL NOT BE ENTITLED TO RECOVER ANY OTHER
DAMAGES, INCLUDING CONSEQUENTIAL, LOST PROFITS, SPECIAL, INDIRECT, PUNITIVE, OR OTHER
DAMAGES.
This limitation applies to (a) anything related to the product, services, content (including code) on third
party Internet sites, or third-party applications; and (b) claims for breach of contract, warranty,
guarantee, or condition; strict liability, negligence, or other tort; or any other claim; in each case to the
extent permitted by applicable law. It also applies even if In Force knew or should have known about the
possibility of the damages. The above limitation or exclusion applies to the fullest extent allowed by
Massachusetts and federal law.`;
