<ion-header>
  <ion-toolbar>
    <ion-button *ngIf="!selectingSchools" slot="start" (click)="onSetSelectingSchools(true)">
      <ion-icon color="secondary" name="arrow-back"></ion-icon>
    </ion-button>
    <ion-title>
      Send Reverse Alert
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <new-reverse-alert-schools
    *ngIf="selectingSchools; else enteringDetails"
    [schools]="schools"
    [schoolDistricts]="schoolDistricts"
    [allSchoolsSelected]="allSchoolsSelected"
    (selectSchool)="onSelectSchool($event)"
    (selectAllSchools)="onSelectAllSchools($event)"
    (selectDistrict)="onSelectDistrict($event)"
    (setSelectingSchools)="onSetSelectingSchools(false)"
    (clearAllLocations)="onClearAllLocations()"
  ></new-reverse-alert-schools>
  <ng-template #enteringDetails>
    <new-reverse-alert-details
      [reverseAlert]="reverseAlert"
      (setSelectingSchools)="onSetSelectingSchools(true)"
      (presentConfirmation)="onPresentConfirmation()"
      (clearAllDetails)="onClearAllDetails()"
    ></new-reverse-alert-details>
  </ng-template>
</ion-content>
