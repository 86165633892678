import { Component, Input } from '@angular/core';
import { computed } from 'mobx-angular';

import { Alert } from 'src/app/shared/models/alert';
import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'alert-details',
  templateUrl: './alert-details.component.html',
  styleUrls: ['./alert-details.component.scss']
})
export class AlertDetailsComponent {
  @Input() alert: Alert | undefined = undefined;
  maxDays = 99;

  constructor(private dateService: DateService) {}

  @computed get timeSinceCreated() {
    if(!this.alert) return '';
    return this.dateService.timeElapsedSinceDate(new Date(this.alert.createdDate + "Z"), this.maxDays);
  }
}
