<ion-header>
  <ion-toolbar>
    <ion-title>
      Select Your Location
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section>
    <ng-container *ngIf="locations && locations.length > 0; else noLocations">
      <article>
        <ng-container *ngFor="let location of locations">
          <ion-button (click)="startNewAlert(location)">
            <span class="text-ellipsis">{{ location.location }}</span>
          </ion-button>
        </ng-container>
      </article>
    </ng-container>
    <ng-template #noLocations>
      <p>There are currently no preset locations</p>
    </ng-template>
  </section>
</ion-content>
