import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { NewAlertPage } from './new-alert.page';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from 'src/app/shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: NewAlertPage,
    canActivate: [authGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    RouterModule.forChild(routes),
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 40,
      space: -10,
      outerStrokeWidth: 10,
      innerStrokeWidth: 10,
      outerStrokeColor: '#B50000',
      innerStrokeColor: '#C1C1C1',
      animation: false,
      renderOnClick: false,
      showTitle: true,
      showSubtitle: false,
      showUnits: false,
      startFromZero: true,
      titleFontSize: '32px',
      titleColor: '#B50000'
    })
  ],
  declarations: [NewAlertPage]
})
export class NewAlertPageModule {}
