import { Component, OnInit } from '@angular/core';
import { AlertController, Platform, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { EventService } from 'src/app/shared/services/events.service';
import { Router } from '@angular/router';

import { LoadingService } from 'src/app/shared/services/loading.service';
import { SchoolService } from 'src/app/shared/services/school.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AgencySchool, School } from 'src/app/shared/models/school';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SchoolDistrictsList } from 'src/app/shared/models/schoolDistrictsList';
import { SchoolDistrict } from 'src/app/shared/models/schoolDistrict';
import { ChatService } from 'src/app/shared/services/chat.service';
import { XMPPConnectionStatus } from 'src/app/shared/enums';
import { Alert } from 'src/app/shared/models/alert';
import { User } from 'src/app/shared/models/user';
import { Location } from 'src/app/shared/models/location';

@Component({
  selector: 'new-internal-notif',
  templateUrl: './new-internal-notif.page.html',
  styleUrls: ['./new-internal-notif.page.scss'],
})
export class NewInternalNotifPage implements ViewWillEnter, ViewWillLeave {
  internalNotif: Alert = {
    address: '',
    alertGuid: '',
    alertId: '',
    alertPreplans: null,
    city: '',
    createdDate: null,
    isReverseAlert: false,
    isInternal: true,
    message: '',
    subject: '',
    schoolId: 2,
    schoolName: '',
    state: '',
    userId: 2,
    userName: '',
    zip: '',
  };
  school: School = null;
  location: Location = null;
  alertId: number = null;
  user: User = null;
  alertGUID: string = null;
  timer = null;
  currentTime = null;
  timerPercentage = 100;
  viewLoaded = false;

  startAlert = false;

  constructor(
    private alertController: AlertController,
    private loadingService: LoadingService,
    private platform: Platform,
    private schoolService: SchoolService,
    private userService: UserService,
    private alertsService: AlertsService,
    private router: Router,
    private chatService: ChatService,
  ) {}

  ionViewWillEnter() {
    this.school = window.history.state.school;
    this.location = window.history.state.location;
    this.user = window.history.state.user;
    
    this.viewLoaded = true;
  }

  ionViewWillLeave() {
    clearInterval(this.timer);
    this.onClearAllDetails();
    this.viewLoaded = false;
  }

  sendInternalNotif($event) {
    this.schoolService
      .getSchool(this.location.schoolId)
      .then((school: School) => {
        this.router.navigateByUrl('tabs/new-alert', { state: { user: this.user, location: this.location, school, isInternal: true, title: $event.title, message: $event.message} });
      })
      .catch((err: any) => {
        const message = 'Failed to start a new alert. Please try again or call 9-1-1 if this is an emergency.';
        this.presentAlert('Error', message);
      });
  }

  async onClearAllDetails() {
    this.internalNotif.message = '';
    this.internalNotif.subject = '';
  }
  async onCancel() {
    this.router.navigateByUrl('tabs/categories');
    this.onClearAllDetails();
  }

  async presentAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      message,
      header,
      buttons: ['OK'],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }
}

