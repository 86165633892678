<ion-header>
  <ion-toolbar>
    <ion-title>
      Resources
    </ion-title>
  </ion-toolbar>
</ion-header>



<ion-content>
  <ng-container *ngIf="resources.length > 0; else noResources">
    <article>
      <ng-container *ngFor="let resource of resources">
        <p>
          <a class="file" (click)="goToFile(resource.filePath)">{{ resource.fileTitle }}</a>
        </p>
      </ng-container>
    </article>
  </ng-container>
  <ng-template #noResources>
    <article>
      <p class="no-resources">No resources available</p>
    </article>
  </ng-template>
</ion-content>
