<ion-tabs>
  <ion-tab-bar slot="bottom" *ngIf="showTabs">
    <ion-tab-button *ngIf="isOrgUser" tab="categories">
      <ion-icon name="home"></ion-icon>
      <ion-label>Home</ion-label>
    </ion-tab-button>

    <ion-tab-button *ngIf="!isOrgUser" tab="new-reverse-alert">
      <ion-icon name="inforce-reverse-alert"></ion-icon>
      <ion-label>Reverse Alert</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="alerts">
      <ion-icon name="chatbox"></ion-icon>
      <ion-label>Alerts</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="resources">
      <ion-icon name="copy"></ion-icon>
      <ion-label>Resources</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="settings">
      <ion-icon name="settings"></ion-icon>
      <ion-label>Settings</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
