<ng-container *ngIf="viewLoaded">
  <ion-header>
    <ion-toolbar>
      <ion-title>
        Alert Confirmation
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <section>
      <circle-progress [percent]="timerPercentage" [title]="currentTime"></circle-progress>
      <ion-button id="confirm-alert" (click)="confirmAlert()" color="danger">CONFIRM ALERT</ion-button>
      <div class="room-info">
        <h3>{{ location.location }}</h3>
        <strong>{{ school.SchoolName }}</strong>
      </div>
      <ion-button id="cancel" (click)="goToLocations()" color="light-blue">Cancel</ion-button>
    </section>
  </ion-content>
</ng-container>
