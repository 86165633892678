import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { eula } from '../EULA';

@Injectable({
  providedIn: 'root'
})
export class UIAlertService {
  showingDialog = false;

  constructor(
    private alertController: AlertController,
    private platform: Platform,
    private router: Router,
    private userService: UserService
  ) {}

  async presentNotificationFailure() {
    const alert = await this.alertController.create({
      message:
        'Failed to enable and register for notifications. ' +
        'Please ensure notifications are enabled in your app settings and log out and log in again. ' +
        'If the problem persists, please contact your administrator.',
      header: 'Notifications Disabled',
      buttons: ['OK'],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }

  async presentNotificationMessage(data: any) {
    const duration = 5000;
    if (this.showingDialog) {
      return;
    }
    this.showingDialog = true;

    const alert = await this.alertController.create({
      message: data.message.replace('Tap', 'Click'),
      header: 'InForce911 Alert',
      buttons: [
        {
          text: 'Open',
          handler: () => this.router.navigate([`/tabs/alerts/${data.alertId}`])
        }
      ],
      mode: this.platform.is('ios') ? 'ios' : 'md',
      backdropDismiss: false
    });

    alert.onDidDismiss().then(() => (this.showingDialog = false));

    await alert.present();

    setTimeout(() => {
      alert.dismiss();
    }, duration);
  }

  async presentExpiredTokenAlert() {
    if (this.showingDialog) {
      return;
    }
    this.showingDialog = true;

    const alert = await this.alertController.create({
      message: 'Your session has expired. Please log back in.',
      header: 'Expired Session',
      buttons: [
        {
          text: 'Return to login',
          handler: () => this.router.navigate(['/'])
        }
      ],
      mode: this.platform.is('ios') ? 'ios' : 'md',
      backdropDismiss: false
    });

    alert.onDidDismiss().then(() => (this.showingDialog = false));

    await alert.present();
  }

  presentEULA() {
    return new Promise(async resolve => {
      const alert = await this.alertController.create({
        message: eula,
        header: 'End-user License Agreement',
        buttons: [
          {
            text: 'Cancel',
            handler: () => resolve(false)
          },
          {
            text: 'Agree',
            handler: () => {
              this.userService.setAcceptedEULA(true);
              resolve(true);
            }
          }
        ],
        mode: this.platform.is('ios') ? 'ios' : 'md',
        backdropDismiss: false
      });
      await alert.present();
    });
  }
}
