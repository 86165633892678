import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'categories-alert-type',
  templateUrl: './categories-alert-type.component.html',
  styleUrls: ['./categories-alert-type.component.scss'],
})
export class CategoriesAlertTypeComponent implements OnInit{

  @Input() isInternalAdmin: boolean = false;
  @Output() setSelectingCategory = new EventEmitter();
  @Output() selectInternal = new EventEmitter();
  @Output() selectLE = new EventEmitter();
  @Output() selectSendNow = new EventEmitter();

  ngOnInit() {

  }

  buttonClickedSendNow() {
    this.selectSendNow.emit();
  }

  buttonClickedInternal() {
    this.selectInternal.emit();
  }

  buttonClickedLE(title: string) {
    this.selectLE.emit({title: title});
  }

}
