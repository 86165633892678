import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Geolocation } from '@capacitor/geolocation';
import { Platform, AlertController } from '@ionic/angular';
import { NativeGeocoder} from "@capgo/nativegeocoder";
import { environment } from '../../../environments/environment';
import { LoadingService } from '../../shared/services/loading.service';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { Location } from '../../shared/models/location';
import { SchoolService } from 'src/app/shared/services/school.service';
import { School } from 'src/app/shared/models/school';
import { Coords } from 'src/app/shared/models/coords';
import { UserService } from 'src/app/shared/services/user.service';
import { User } from 'src/app/shared/models/user';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-locations',
  templateUrl: 'locations.page.html',
  styleUrls: ['locations.page.scss']
})
export class LocationsPage implements OnInit {
  locations: Location[] = [];
  user: User|null = null;
  defaultSchool = null;
  latitude = '';
  longitude = '';
  isBrowser = false;
  isInternal: Boolean = false;
  title: string="";

  constructor(
    private loadingService: LoadingService,
    private schoolService: SchoolService,
    private userService: UserService,
    private applicationService: ApplicationService,
    private router: Router,
    private route: ActivatedRoute,
    private platform: Platform,
    private alertController: AlertController,
  )
  {
    this.route.queryParams.subscribe(params => {
      /*if (this.router.getCurrentNavigation().extras.state) {
        this.isInternal = this.router.getCurrentNavigation().extras.state.isInternal;
      }*/
      this.isInternal = window.history.state.isInternal;
      this.title = window.history.state.title;
    });
  }


  ngOnInit() {
    this.loadingService.present();

    this.isBrowser = this.applicationService.isBrowser;

    this.userService.getUser().then((user: User) => {
      this.user = user;
      this.defaultSchool = user.defaultSchool;
      const userId = parseInt(this.user.uniqueId, 10);
      this.userService
        .getUserLocations(userId)
        .then(response => {
          this.locations = response.locations;
        })
        .catch(() => {
          const message = 'Failed to get your locations. Please try again or call 9-1-1 if this is an emergency.';
          this.presentAlert('Error', message);
        })
        .finally(() => {
          this.loadingService.dismiss();
        });
    });
  }

  getCoords(): Promise<Coords> {
    this.loadingService.present();

    return new Promise(async (resolve, reject) => {
      
      if(Capacitor.isNativePlatform()) {
        let position = await Geolocation.getCurrentPosition();
        await this.loadingService.dismiss();

        if (position === undefined) {
          reject("Error fetching current location.");
        }
  
        resolve(position.coords as Coords);
      }
      else {
        navigator.geolocation.getCurrentPosition((pos) => {
          if (pos === undefined) {
            reject("Error fetching current location.");
          }

          resolve(pos.coords as Coords);
        });

        reject("Error fetching current location.");
      }
    });
  }

  useMyLocation(): void {
    const errorMessage = 'Failed to get your GPS location. Please try again or call 9-1-1 if this is an emergency.';

    this.getCoords()
      .then((coords: Coords) => {
        let address = '';

        NativeGeocoder
          .reverseGeocode({
            latitude: coords.latitude,
            longitude: coords.longitude
          })
          .then(({ addresses }) => {
            address = `${addresses[0].subThoroughfare} ${addresses[0].thoroughfare}, ${addresses[0].locality}, ${
              addresses[0].administrativeArea
              } ${addresses[0].postalCode}, ${addresses[0].countryCode}`;

            this.schoolService.getSchool(this.defaultSchool).then((school: School) => {
              const defaultLocation = {
                Location: address,
                SchoolID: this.defaultSchool,
                Latitude: coords.latitude,
                Longitude: coords.longitude
              };

              this.router.navigateByUrl('tabs/new-alert', {
                state: { user: this.user, location: defaultLocation, school, isInternal: false }
              });
            });
          })
          .catch((err: any) => {
            this.loadingService.dismiss();
            this.presentAlert('Error', errorMessage);
          });
      })
      .catch((err: any) => {
        this.loadingService.dismiss();
        this.presentAlert('Error', errorMessage);
      });
  }

  startNewAlert(location: Location): void {
    if (this.isInternal) {
      this.router.navigateByUrl('tabs/new-internal-notif', {state: { user: this.user, location}})
    }
    else {
      this.schoolService
      .getSchool(location.schoolId)
      .then((school: School) => {
        this.router.navigateByUrl('tabs/new-alert', { state: { user: this.user, location, school, title: this.title } });
      })
      .catch((err: any) => {
        const message = 'Failed to start a new alert. Please try again or call 9-1-1 if this is an emergency.';
        this.presentAlert('Error', message);
      });
    }
   
  }

  async presentAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      message,
      header,
      buttons: ['OK'],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }
}
