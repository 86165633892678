<ion-header>
  <ion-toolbar>
    <ion-title>
      Settings
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <logout></logout>
  <p><a (click)="goToTermsAndConditions()">Terms and Conditions</a></p>
  <p><a (click)="goToPrivacyPolicy()">Privacy Policy</a></p>
</ion-content>
