import { Component } from '@angular/core';
import { EventService } from 'src/app/shared/services/events.service';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { KeyboardService } from 'src/app/shared/services/keyboard.service';
import { computed } from 'mobx-angular';
import { ViewWillEnter } from '@ionic/angular';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage implements ViewWillEnter {
  onTabsPage = true;
  isOrgUser: boolean = this.applicationService.isOrgUser;

  constructor(
    private events: EventService,
    private applicationService: ApplicationService,
    private keyboardService: KeyboardService,
  ) {
    
  }

  @computed get showTabs() {
    return this.onTabsPage && !this.keyboardService.keyboardShowing;
  }

  async ionViewWillEnter() {
    this.events.tabsChangedSource$.subscribe(this.onTabsChanged.bind(this));
  }

  onTabsChanged(onTabsPage: boolean) {
    this.onTabsPage = onTabsPage;
  }
}
