import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from 'src/app/shared/services/events.service';

import { Alert } from 'src/app/shared/models/alert';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { AlertDataService } from 'src/app/shared/services/alertData.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';

// Page that shows when tab "Alerts" is selected
@Component({
  selector: 'app-alerts',
  templateUrl: 'alerts.page.html',
  styleUrls: ['alerts.page.scss']
})
export class AlertsPage implements ViewWillEnter, ViewWillLeave {
  alerts: Alert[] = [];
  refreshTime = 5000;
  fetchInterval = null;

  constructor(
    private router: Router,
    private alertsService: AlertsService,
    private loadingService: LoadingService,
    private chatService: ChatService,
    private alertDataService: AlertDataService,
    private events: EventService
  ) {}

  ionViewWillEnter() {
    this.loadingService.present();

    this.chatService.attemptConnection();

    this.events.tabsChangedRefresh(true);
    this.events.initAlertReceivedSource$.subscribe(this.refreshAlertList.bind(this));
    this.events.closeAlertReceivedSource$.subscribe(this.alertClosed.bind(this));

    this.fetchAlerts().then((alerts: Alert[]) => {
      this.loadingService.dismiss();
      this.alerts = alerts;
    });

    this.fetchInterval = setInterval(() => {
      this.fetchAlerts()
        .then((alerts: Alert[]) => {
          this.alerts = alerts;
        })
        .catch((err: any) => {
          // Stop the timer, close the connection, and unsubscribe
          // if the auth token is expired
          if (err.status === 401) {
            clearInterval(this.fetchInterval);
            this.chatService.closeConnection();
          }
        });
    }, this.refreshTime);
  }

  ionViewWillLeave() {
    clearInterval(this.fetchInterval);
  }

  refreshAlertList() {
    this.fetchAlerts().then((alerts: Alert[]) => {
      this.alerts = alerts;
    });
  }

  fetchAlerts(): Promise<Alert[]> {
    return new Promise((resolve, reject) => {
      this.alertsService
        .getActiveAlertList()
        .then((fetchedAlerts: Alert[]) => {
          fetchedAlerts = fetchedAlerts.sort((a, b) => +new Date(b.createdDate) - +new Date(a.createdDate));
          resolve(fetchedAlerts);
        })
        .catch(err => reject(err));
    });
  }

  onGoToAlert(alert: Alert) {
    clearInterval(this.fetchInterval);
    this.alertDataService.setData(parseInt(alert.alertId, 10), alert);
    this.router.navigate(['/tabs/alerts/' + alert.alertId]);
  }

  alertClosed(alertId: string) {
    this.alerts = this.alerts.filter((alert: Alert) => alert.alertId !== alertId);
  }
}
