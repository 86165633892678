import { Component, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { EventService } from 'src/app/shared/services/events.service';

@Component({
  selector: 'app-settings',
  templateUrl: 'settings.page.html',
  styleUrls: ['settings.page.scss']
})
export class SettingsPage implements ViewWillEnter {
  constructor(private events: EventService) {}

  ionViewWillEnter() {
    this.events.tabsChangedRefresh(true);
  }

  goToTermsAndConditions() {
    window.open('https://www.inforcetechnology.com/terms-and-conditions', '_self');
  }

  goToPrivacyPolicy() {
    window.open('https://www.inforcetechnology.com/privacy-policy', '_self');
  }
}
