import { Component, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { computed } from 'mobx-angular';
import { ApplicationService } from 'src/app/shared/services/application.service';

@Component({
  selector: 'message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.scss']
})
export class MessageInputComponent {
  static readonly maxHeight = 150;

  @Input() messageText: string | undefined;
  @Input() alertClosed: boolean | undefined;
  @Output() setMessageText = new EventEmitter<string>();
  @Output() sendMessage = new EventEmitter<string>();

  constructor(private element: ElementRef, private appService: ApplicationService) {}

  @computed get hasMessageText(): boolean {
    return this.messageText !== null && this.messageText !== undefined && this.messageText !== '';
  }

  onChange(e: any) {
    this.setMessageText.emit(e.target.value);
    this.resizeMessageInput();
  }

  onSendMessage() {
    this.sendMessage.emit();
  }

  onKeyUp(event: any) {
    // If shift + enter pressed, allow newline to be added to message text, if
    //   enter only and on browser, send message and omit the last (newline) character
    if (
      this.appService.isBrowser &&
      event &&
      event.key === 'Enter' &&
      event.shiftKey !== undefined &&
      event.shiftKey === false &&
      this.messageText && this.messageText.length > 0
    ) {
      this.setMessageText.emit(this.messageText.substring(0, this.messageText.length - 1));
      this.sendMessage.emit();
    }
  }

  resizeMessageInput() {
    const textArea = this.element.nativeElement.getElementsByTagName('textarea')[0];

    // This line is necessary to reset the scrollHeight
    // which triggers the conditional below
    textArea.style.height = 'auto';

    if (textArea.scrollHeight <= MessageInputComponent.maxHeight) {
      textArea.style.height = textArea.scrollHeight + 'px';
    } else {
      textArea.style.height = MessageInputComponent.maxHeight + 'px';
    }
  }
}
