<ng-container *ngIf="alert">
  <ng-container *ngIf="!alert.isReverseAlert; else reverseAlert">
    <ng-container *ngIf="!alert.isInternal; else internalNotif">
      <div class="alert-info normal-alert">
        <span class="time-since-started">{{ timeSinceCreated }}</span>
        <div class="detail-container">
          <ion-icon name="inforce-warning-icon"></ion-icon>
          <p class="subject">{{ alert.subject }}</p>
          <p class="school-name">{{ alert.schoolName }}</p>
        </div>
        <div class="detail-container">
          <ion-icon name="inforce-location-icon"></ion-icon>
          <p class="school-address">{{ alert.address }}, {{ alert.city }}, {{ alert.state }} {{ alert.zip }}</p>
        </div>
        <p class="origin">{{ alert.userName }}</p>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #internalNotif>
    <div class="alert-info internal-notif">
      <span class="time-since-started">{{ timeSinceCreated }}</span>
      <div class="detail-container">
        <ion-icon name="inforce-shield-icon"></ion-icon>
        <p class="subject">{{ alert.subject }}</p>
        <p class="school-name">{{ alert.schoolName }}</p>
      </div>
      <div class="detail-container">
        <ion-icon name="inforce-location-icon"></ion-icon>
        <p class="school-address">{{ alert.address }}, {{ alert.city }}, {{ alert.state }} {{ alert.zip }}</p>
      </div>
      <p class="origin">{{ alert.userName }}</p>
    </div>
  </ng-template>
  <ng-template #reverseAlert>
    <div class="alert-info reverse-alert">
      <span class="time-since-started">{{ timeSinceCreated }}</span>
      <div class="detail-container">
        <ion-icon name="inforce-shield-icon"></ion-icon>
        <p class="subject">{{ alert.subject }}</p>
      </div>
      <p class="origin">{{ alert.userName }}</p>
    </div>
  </ng-template>
</ng-container>
