import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { LoadingService } from './shared/services/loading.service';
import { StatusBar } from '@capacitor/status-bar';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public token = "";

  constructor(
    private platform: Platform,
    private loadingService: LoadingService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.loadingService.present();
    this.platform.ready().then(async () => {
      // Only on mobile devices
      if (this.platform.is('capacitor')) {        
        screen.orientation.lock('portrait').catch(err => console.log(err));
        
        //TODO: Preston - can we remove tis line?
        StatusBar.show();
        
        SplashScreen.hide();
      }
    });
  }
}
