<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button id="alert-list-button" (click)="login()" color="secondary"
        ><ion-icon name="arrow-back"></ion-icon
      ></ion-button>
    </ion-buttons>
    <ion-title>Password Reset</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content (keyup.enter)="submitForgotPassword()">
  <ion-grid>
    <ion-row>
      <ion-col align-self-center>
        <ion-img class="logo" src="../../assets/logo.png"></ion-img>
        <form #f="ngForm" (ngSubmit)="submitForgotPassword()">
          <div class="input-wrapper">
            <ion-item *ngIf="isOrgUser" no-padding>
              <ion-icon [ngClass]="{ active: user.uniqueId }" src="assets/user.svg"></ion-icon>
              <ion-input
                type="text"
                maxlength="64"
                placeholder="Unique ID"
                name="uniqueId"
                [(ngModel)]="user.uniqueId"
              ></ion-input>
            </ion-item>
            <ion-item no-padding>
              <ion-icon [ngClass]="{ active: user.username }" src="assets/user.svg"></ion-icon>
              <ion-input
                type="text"
                maxlength="64"
                placeholder="Username"
                name="username"
                [(ngModel)]="user.username"
              ></ion-input>
            </ion-item>
          </div>
          <ion-button
            type="submit"
            [color]="formIsValid ? 'primary' : 'light-grey'"
            [disabled]="!formIsValid"
            expand="block"
          >
            Submit
          </ion-button>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
