<ion-header>
  <ion-toolbar>
    <ion-button slot="start" id="alert-list-button" (click)="goToAlertList()">
      <ion-icon color="secondary" name="arrow-back"></ion-icon>
    </ion-button>
    <ion-title *ngIf="alert && !alert.isReverseAlert && !alert.isInternal">Alert Chat</ion-title>
    <ion-title *ngIf="alert && !alert.isReverseAlert && alert.isInternal">Internal Notification Chat</ion-title>
    <ion-title *ngIf="alert && alert.isReverseAlert">Reverse Alert Chat</ion-title>
    <ion-button *ngIf="isOrgUser" id="user-list-button" slot="end" (click)="showUserList()"></ion-button>
    <ion-button *ngIf="!isOrgUser" slot="end" (click)="showUserList()"
      ><ion-icon color="secondary" name="information-circle-outline"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<!-- Sidebar menu -->
<ion-menu contentId="alert-content" class="user-list" menuId="user-list" side="end" type="overlay">
  <ion-header>
    <ion-toolbar color="blue">
      <ion-title>{{ isOrgUser ? 'User List' : 'Alert Info' }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="menu-content">
    <section class="menu-lists">
      <!-- Only regular alerts have files associated with them -->
      <article class="file-list" *ngIf="!isOrgUser && alert && !alert.isReverseAlert">
        <ion-label>FILES</ion-label>
        <ion-list>
          <p *ngFor="let alertPreplan of alert.alertPreplans">
            <a class="file" (click)="goToFile(alertPreplan.filePath)">{{ alertPreplan.uploadedFileName }}</a>
          </p>
        </ion-list>
      </article>
      <article class="participant-list">
        <ion-label>DISPATCH</ion-label>
        <ion-list>
          <p *ngFor="let user of participants.dispatchUsers">{{ user.userName }}</p>
        </ion-list>
        <ion-label>OFFICERS</ion-label>
        <ion-list>
          <p *ngFor="let user of participants.officerUsers">{{ user.userName }}</p>
        </ion-list>
        <ion-label>LOCATION USERS</ion-label>
        <ion-list>
          <p *ngFor="let user of participants.locationUsers">{{ user.userName }}</p>
        </ion-list>
      </article>
      <ng-container *ngIf="isInternalAdmin && alert.isInternal && !alertClosed">
        <article class="forward-list">
          <ion-label>FORWARD TO</ion-label>
          <ion-row>
            <ion-label class="law-enforcement-label">
              Law Enforcement
            </ion-label>
          </ion-row>
          <ion-button *ngFor="let label of userGroupsLE" (click)="presentForwardConfirmationLE(label)">
            {{ label }}
          </ion-button>
          <ion-row>
            <ion-label class="organization-label">
              Organization
            </ion-label>
          </ion-row>
          <ion-button *ngFor="let label of userGroupsInternal" (click)="presentForwardConfirmationInternal(label)">
            {{ label }}
          </ion-button>
        </article>
      </ng-container>
      <div class="spacer"></div>
      <ion-button
      *ngIf="canCloseAlert"
      [disabled]="alertClosed"
      id="close-alert-button"
      color="danger"
      (click)="presentCloseAlertConfirmation()"
      >Close Alert</ion-button
    >
    </section>
  </ion-content>
</ion-menu>

<alert-details [alert]="alert"></alert-details>
<ion-content #content id="alert-content">
  <message-list [messages]="messages"></message-list>
</ion-content>
<p *ngIf="alertClosed" class="alert-closed">Alert Closed</p>
<div class="list msg-input-container">
  <div class="item item-input-inset">
    <message-input
      [messageText]="messageText"
      [alertClosed]="alertClosed"
      (setMessageText)="onSetMessageText($event)"
      (sendMessage)="onSendMessage()"
    ></message-input>
  </div>
</div>
