import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';

import { LoginService } from '../services/login.service';
import { UserService } from '../services/user.service';
import { LoadingService } from '../services/loading.service';
import { NavController } from '@ionic/angular';

export const loginGuard: CanActivateFn = () => {
  const userService = inject(UserService);
  const loadingService = inject(LoadingService);
  const loginService = inject(LoginService);
  const router = inject(NavController);

  return Promise.all([userService.getUser(), userService.getAuthToken()])
    .then((values: any[]) => {
      if (values[0] && values[1] && userService.getSessionToken()) {
        // We have a user and a valid auth token in storage
        return loginService.validate().then(() => {
          loadingService.dismiss();
          router.navigateForward(['/tabs/categories']);
          return false;
        });
      }
      // We are missing a user or an auth token in storage
      loadingService.dismiss().then(() => {
        return true;
      });
    })
    .catch((err: any) => {
      // Our auth token is invalid
      loadingService.dismiss();
      return true;
    });
};