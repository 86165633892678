import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Message } from "../models/message";

@Injectable({
  providedIn: 'root'
})
export class EventService{
  private tabsChangedSource = new Subject<boolean>();
  private connectionEstablishedSource = new Subject<void>();
  private initiateAlertSource = new Subject<any>();
  private initAlertReceivedSource = new Subject<void>();
  private closeAlertReceivedSource = new Subject<string>();
  private messageReceivedSource = new Subject<Message>();
  private messageSentSource = new Subject<Message>();
  private closeAlertSource = new Subject<boolean>();

  tabsChangedSource$ = this.tabsChangedSource.asObservable();
  connectionEstablishedSource$ = this.connectionEstablishedSource.asObservable();
  initiateAlertSource$ = this.initiateAlertSource.asObservable();
  initAlertReceivedSource$ = this.initAlertReceivedSource.asObservable();
  closeAlertReceivedSource$ = this.closeAlertReceivedSource.asObservable();
  messageReceivedSource$ = this.messageReceivedSource.asObservable();
  messageSentSource$ = this.messageSentSource.asObservable();
  closeAlertSource$ = this.closeAlertSource.asObservable();

  tabsChangedRefresh(message: boolean) {
    this.tabsChangedSource.next(message);
  }

  connectionEstablishedRefresh() {
    this.connectionEstablishedSource.next();
  }

  initiateAlertRefresh(message: any) {
    this.initiateAlertSource.next(message);
  }

  initAlertReceivedRefresh() {
    this.initAlertReceivedSource.next(void 0);
  }

  closeAlertReceivedRefresh(alertId: string) {
    this.closeAlertReceivedSource.next(alertId);
  }

  messageReceivedRefresh(message : Message) {
    this.messageReceivedSource.next(message);
  }

  messageSentRefresh(message: Message) {
    this.messageSentSource.next(message);
  }

  closeAlertRefresh(message: boolean) {
    this.closeAlertSource.next(message);
  }
}