<ng-container *ngIf="messages.length > 0">
  <article>
    <ng-container #messageList *ngFor="let message of messages">
      <div
        [ngClass]="{
          school: message.isSchoolUser,
          police: !message.isSchoolUser
        }"
        class="message"
      >
        <p class="author">{{ message.name }}</p>
        <p class="content">{{ message.msg }}</p>
        <p class="time">{{ message.createdTime }}</p>
      </div>
    </ng-container>
  </article>
</ng-container>
