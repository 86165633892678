import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { computed } from 'mobx-angular';

import { AgencySchool } from 'src/app/shared/models/school';
import { SchoolDistrict } from 'src/app/shared/models/schoolDistrict';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'new-reverse-alert-schools',
  templateUrl: './new-reverse-alert-schools.component.html',
  styleUrls: ['./new-reverse-alert-schools.component.scss']
})
export class NewReverseAlertSchoolsComponent implements OnInit {
  @Input() schools: AgencySchool[] | null = null;
  @Input() schoolDistricts: SchoolDistrict[] | null = null;
  @Input() allSchoolsSelected: boolean | null = null;
  @Output() selectSchool = new EventEmitter<{checked: any, schoolId: number, districtId: number}>();
  @Output() selectAllSchools = new EventEmitter<boolean>();
  @Output() selectDistrict = new EventEmitter<{checked: any, districtId: number}>();
  @Output() setSelectingSchools = new EventEmitter<boolean>();
  @Output() clearAllLocations = new EventEmitter();

  ngOnInit() {
    this.clearAllLocations.emit();
  }

  @computed get atLeastOneSchoolSelected() {
    if (!this.schools) return false;
    return this.schools.some((school: AgencySchool) => school.selected);
  }

  onSelectSchool(e: any, schoolId: number, districtId: number): void {
    const checked = e.detail.checked;
    const details = {
      checked,
      schoolId,
      districtId
    };
    this.selectSchool.emit(details);
  }

  onSelectAllSchools(e: any): void {
    const checked = e.detail.checked;
    this.selectAllSchools.emit(checked);
  }

  onSelectDistrict(e: any, districtId: number): void {
    const checked = e.detail.checked;
    const details = {
      checked,
      districtId
    };
    this.selectDistrict.emit(details);
  }

  onContinue(): void {
    this.setSelectingSchools.emit(false);
  }
}
