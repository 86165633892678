import { Component } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { observable, computed } from 'mobx-angular';

import { User } from '../../shared/models/user';
import { LoginService } from '../../shared/services/login.service';
import { LoadingService } from '../../shared/services/loading.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { ApplicationService } from 'src/app/shared/services/application.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss']
})
export class LoginPage {
  submitting = false;
  isOrgUser: boolean = this.applicationService.isOrgUser;

  @observable user: User = {
    uniqueId: '',
    agencyId: null,
    username: '',
    password: '',
    schoolDistrictId: null,
    defaultSchool: null,
    displayName: null,
    userTypeId: null,
    isDispatcher: false,
    canCloseAlerts: false,
    canCloseReverseAlerts: false,
    canManageInternalNotifs: false,
    xmppConnection: null
  };

  @computed get formIsValid(): boolean {
    if (this.isOrgUser) {
      return this.user.uniqueId !== '' && this.user.username !== '' && this.user.password !== '';
    } else {
      return this.user.username !== '' && this.user.password !== '';
    }
  }

  constructor(
    private loadingService: LoadingService,
    private loginService: LoginService,
    private chatService: ChatService,
    private applicationService: ApplicationService,
    private alertController: AlertController,
    private platform: Platform,
    private router: Router
  ) {}

  ionViewWillEnter() {
    this.chatService.closeConnection();
  }

  submitLogin(): void {
    if (!this.formIsValid || this.submitting) {
      return;
    }
    this.submitting = true;
    this.loginService
      .attemptLogin(this.user, this.formIsValid)
      .then(() => {
        this.loadingService.dismiss();
        const navigateTo = this.isOrgUser ? '/tabs/categories' : '/tabs/new-reverse-alert';
        this.router.navigate([navigateTo]);
        this.user.uniqueId = '';
        this.user.username = '';
        this.user.password = '';
        this.submitting = false;
      })
      .catch((err: string) => {
        this.loadingService.dismiss();
        this.presentAlert(err);
        this.submitting = false;
      });
  }

  forgotPassword(): void {
    this.router.navigate(['/forgot-password'], { skipLocationChange: true });
  }

  async presentAlert(message: string) {
    this.loadingService.dismiss();
    const alert = await this.alertController.create({
      message,
      header: 'Error',
      buttons: ['OK'],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }
}
