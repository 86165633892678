export enum ApplicationVersion {
  Organization = 0,
  Agency = 1
}

export enum PlatformType {
  Phone = 0,
  Browser = 1
}

export enum DeviceType {
  iPad = 1,
  iPhone = 2,
  Android = 3,
  Windows = 4,
  MAC = 5,
  Browser = 6
}

export enum ApplicationUserType {
  InforceAdmin = 0,
  AgencyAdmin = 1,
  AgencyUser = 2,
  SchoolDistrictAdmin = 3,
  SchoolDistrictUser = 4,
  SchoolAdmin = 5,
  SchoolUser = 6,
  Dispatcher = 7
}

export enum XMPPMessageAction {
  None = 0,
  GDLUpdate = 1,
  InitAlert = 2,
  AlertMessage = 3,
  CloseAlert = 4,
  UserNameChanged = 5,
  Online = 6,
  Offline = 7,
  ForwardAlert = 8
}

export enum ChatUserType {
  None = 0,
  Dispatch = 1,
  Officer = 2,
  SchoolStaff = 3
}

export enum AlertType {
  Alert = 1,
  ReverseAlert = 2
}

export enum XMPPConnectionStatus {
  ERROR = 0,
  CONNECTING = 1,
  CONNFAIL = 2,
  AUTHENTICATING = 3,
  AUTHFAIL = 4,
  CONNECTED = 5,
  DISCONNECTED = 6,
  DISCONNECTING = 7,
  ATTACHED = 8,
  REDIRECT = 9,
  CONNTIMEOUT = 10
}
