<section>
  <div class="input subject">
    <ion-label position="stacked">Subject <span class="required">*</span></ion-label>
    <ion-input
      maxlength="250"
      placeholder="Subject"
      (ionFocus)="onFocus()"
      (ionBlur)="onBlur()"
      [(ngModel)]="reverseAlert.subject"
    ></ion-input>
  </div>
  <div class="input message">
    <ion-label position="stacked">Message <span class="required">*</span></ion-label>
    <ion-textarea
      maxlength="2000"
      placeholder="Message"
      (ionFocus)="onFocus()"
      (ionBlur)="onBlur()"
      [(ngModel)]="reverseAlert.message"
    ></ion-textarea>
  </div>

  <div *ngIf="!keyboardShowing" class="buttons" #buttons>
    <ion-button (click)="onSetSelectingSchools()" color="secondary">Cancel</ion-button>
    <ion-button [disabled]="!canSendReverseAlert" (click)="onPresentConfirmation()" color="danger">Send</ion-button>
  </div>
</section>
