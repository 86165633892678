import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, NavController, Platform, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { EventService } from 'src/app/shared/services/events.service';
import { Router } from '@angular/router';

import { LoadingService } from 'src/app/shared/services/loading.service';
import { SchoolService } from 'src/app/shared/services/school.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AgencySchool } from 'src/app/shared/models/school';
import { User } from 'src/app/shared/models/user';
import { Location } from 'src/app/shared/models/location';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SchoolDistrict } from 'src/app/shared/models/schoolDistrict';
import { ChatService } from 'src/app/shared/services/chat.service';
import { XMPPConnectionStatus } from 'src/app/shared/enums';
import { School } from 'src/app/shared/models/school';

@Component({
  selector: 'categories',
  templateUrl: './categories.page.html',
  styleUrls: ['./categories.page.scss']
})
export class CategoriesPage implements ViewWillEnter, ViewWillLeave {
  
  isSelectingCategory = true;
  allSchoolsSelected = false;
  schools: AgencySchool[] = [];
  schoolDistricts: SchoolDistrict[] = [];
  startAlert = false;
  user: User = null;
  latitude = '';
  longitude = '';
  isBrowser = false;
  isInternal: Boolean;

  constructor(
    private alertController: AlertController,
    private loadingService: LoadingService,
    private platform: Platform,
    private schoolService: SchoolService,
    private applicationService: ApplicationService,
    private userService: UserService,
    private alertsService: AlertsService,
    private router: Router,
    private nav: NavController,
    private chatService: ChatService,
    private events: EventService
  ) 
  {}

  ionViewWillEnter() {
    // TODO: This is a workaround for those reopening the app. need to apply to new reserve alert too.
    if(this.router.url === '/') {
      this.nav.navigateRoot('/tabs');
      return;
    }

    this.loadingService.present();
    this.chatService.attemptConnection();
    this.events.tabsChangedRefresh(true);
    this.isBrowser = this.applicationService.isBrowser;
    //this.isSelectingCategory = true;
    this.userService.getUser().then((user: User) => {
      this.user = user;
      const userId = parseInt(this.user.uniqueId, 10);
      this.loadingService.dismiss();
      this.isSelectingCategory = true;
      /* This will be needed for custom notif types, modify "locations" stuff accordingly
      this.userService
        .getUserLocations(userId)
        .then(response => {
          this.locations = response.locations;
        })
        .catch(() => {
          const message = 'Failed to get your locations. Please try again or call 9-1-1 if this is an emergency.';
          this.presentAlert('Error', message);
        })
        .finally(() => {
          this.loadingService.dismiss();
        });*/
    });
  }

  getUserIsInternalAdmin(): boolean {
    return this.user?.canManageInternalNotifs ?? false;
  }
  onSetIsSelectingCategory(selectingCategory: boolean): void {
    this.isSelectingCategory = selectingCategory;
  }

  ionViewWillLeave() {
    
  }

  selectSendNow() {
    this.schoolService
      .getSchool(this.user.defaultSchool.schoolId)
      .then((school: School) => {
        this.router.navigateByUrl('tabs/new-alert', { state: { user: this.user, location: this.user.defaultSchool, school, isInternal: false, title: "Alert" } });
      })
      .catch((err: any) => {
        const message = 'Failed to start a new alert. Please try again or call 9-1-1 if this is an emergency.';
        this.presentAlert('Error', message);
      });
  }

  selectInternal() {
    //this.isSelectingCategory=false;
    this.router.navigateByUrl('tabs/locations', { state: { isInternal: true } })
  }

  selectLE($event) {
    this.router.navigateByUrl('tabs/locations', { state: { isInternal: false, title: $event.title} });
  }

  /*sendInternalNotifMessage() {
    if (!this.startAlert) {
      return;
    }

    this.chatService.initInternalNotifMessage(this.internalNotif);

    this.startAlert = false;
    this.internalNotif.subject = '';
    this.internalNotif.message = '';
    this.internalNotif.schoolIds = [];
    this.internalNotif.schoolDistrictIds = [];
    this.router.navigate(['tabs/alerts/' + this.internalNotif.alertId]).then(() => (this.isSelectingCategory = true));
  }

  onClearAllDetails(): void {
    this.internalNotif.subject = '';
    this.internalNotif.message = '';
  }

  sendInternalNotif() {
    this.internalNotif.schoolIds = this.schools
      .filter((school: AgencySchool) => school.selected)
      .map((s: AgencySchool) => s.schoolId);

    this.schools.forEach((school: AgencySchool) => {
      if (this.internalNotif.schoolDistrictIds.indexOf(school.parentSchoolId) < 0) {
        this.internalNotif.schoolDistrictIds.push(school.parentSchoolId);
      }
    });

    this.alertsService
      .startNewInternalNotif(this.internalNotif)
      .then((response: any) => {
        this.startAlert = true;
        this.internalNotif.alertId = response.alertId;
        this.internalNotif.alertGuid = response.alertGuid;
        this.chatService.setAlertId(response.alertId);

        if (this.chatService.getConnectionStatus() === XMPPConnectionStatus.CONNECTED) {
          this.sendInternalNotifMessage();
        }

        this.loadingService.dismiss();
      })
      .catch((err: any) => {
        this.loadingService.dismiss();
        this.presentAlert('Error', 'There was a problem sending the internal notification. Please try again.');
      });
  }

  async onPresentConfirmation() {
    const alert = await this.alertController.create({
      message: 'Are you sure you want to send this internal notification?',
      header: 'Confirmation',
      buttons: [
        'Cancel',
        {
          text: 'Send',
          handler: () => {
            this.loadingService.present();
            this.sendInternalNotif();
          }
        }
      ],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }
*/
  async presentAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      message,
      header,
      buttons: ['OK'],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }
}
