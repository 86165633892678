import { Injectable } from '@angular/core';
import 'strophejs-plugin-stream-management';
import 'strophejs-plugin-pubsub';
import { format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { AlertController, Platform } from '@ionic/angular';
import { EventService } from './events.service';
import { v4 as uuid } from 'uuid';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { XMPPConnection } from '../models/xmppConnection';
import { UserService } from './user.service';
import { Message } from '../models/message';
import { Location } from '../models/location';
import { User } from 'src/app/shared/models/user';
import { XMPPMessageAction, ChatUserType, ApplicationUserType, AlertType, XMPPConnectionStatus } from '../enums';
import { ReverseAlert } from '../models/reverseAlert';
import { Subscription } from '../models/subscription';
import { UIAlertService } from './uiAlert.service';
import { Subject } from 'rxjs';
import { DateService } from './date.service';

declare var Strophe: any;
declare var $iq: any;

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  sessionId: string | null = null;
  connection = null;
  xmppConnection: XMPPConnection | null = null;
  alertId: number| null = null;
  user: User | null = null;
  connected = false;
  subscriptionsSubject = new Subject<Subscription[]>();

  attemptReconnect = false;
  connectionStatus: XMPPConnectionStatus = XMPPConnectionStatus.DISCONNECTED;
  maxRetryAttempts = 5;
  connectionInterval: number = null;
  retryTimeout = 10000;

  constructor(
    private events: EventService,
    private userService: UserService,
    private dateService: DateService,
    private http: HttpClient,
    private alertController: AlertController,
    private platform: Platform,
    private uiAlertService: UIAlertService
  ) {}

  attemptConnection() {
    this.attemptReconnect = true;

    if (this.connectionStatus === XMPPConnectionStatus.CONNECTED) {
      this.handleSubscriptions();
    }

    if (this.connectionInterval === null) {
      this.connectionInterval = setInterval(() => {
        if (
          this.connectionStatus !== XMPPConnectionStatus.CONNECTED &&
          this.connectionStatus !== XMPPConnectionStatus.CONNECTING
        ) {
          this.connect();
        }
      }, this.retryTimeout);
    }
  }

  connect() {
    this.userService
      .getXMPPConnection()
      .then((xmppConnection: XMPPConnection) => {
        this.xmppConnection = xmppConnection;

        if (this.connection === null) {
          const connection = new Strophe.Connection(this.xmppConnection.boshUrl, {
            keepalive: true,
          });
          connection.connect(this.xmppConnection.jabberId, this.xmppConnection.password, (status, r) => {
            this.setConnectionStatus(status);
            this.connection = connection;
            if (status === Strophe.Status.CONNECTED) {
              this.onConnect();
            } else if (status === Strophe.Status.DISCONNECTED) {
              this.connected = false;
              this.onDisconnect();
            }
          });
        }
        return this.userService.getUser();
      })
      .then(user => {
        this.user = user;
      })
      .catch(err => {
        console.log(err);
      });
  }

  setAlertId(alertId: number) {
    this.alertId = alertId;
  }

  getAlertId() {
    return this.alertId;
  }

  setConnectionStatus(status: any) {
    switch (status) {
      case 0:
        this.connectionStatus = XMPPConnectionStatus.ERROR;
        return;

      case 1:
        this.connectionStatus = XMPPConnectionStatus.CONNECTING;
        return;

      case 2:
        this.connectionStatus = XMPPConnectionStatus.CONNFAIL;
        return;

      case 3:
        this.connectionStatus = XMPPConnectionStatus.AUTHENTICATING;
        return;

      case 4:
        this.connectionStatus = XMPPConnectionStatus.AUTHFAIL;
        return;

      case 5:
        this.connectionStatus = XMPPConnectionStatus.CONNECTED;
        return;

      case 6:
        this.connectionStatus = XMPPConnectionStatus.DISCONNECTED;
        return;

      case 7:
        this.connectionStatus = XMPPConnectionStatus.DISCONNECTING;
        return;

      case 8:
        this.connectionStatus = XMPPConnectionStatus.ATTACHED;
        return;

      case 9:
        this.connectionStatus = XMPPConnectionStatus.REDIRECT;
        return;

      case 10:
        this.connectionStatus = XMPPConnectionStatus.CONNTIMEOUT;
        return;
    }
  }

  getConnectionStatus() {
    return this.connectionStatus;
  }

  closeConnection() {
    clearInterval(this.connectionInterval);
    this.connectionInterval = null;

    if (this.connection !== null && this.connection.jabberId !== null && this.connection.jabberId !== '') {
      this.connection.flush();
      this.connection.disconnect();
      this.xmppConnection = null;
    }
  }

  onConnect() {
    const iq = $iq({ type: 'get' }).c('query', { xmlns: 'jabber:iq:roster' });
    this.connection.sendIQ(iq, () => {
      this.handleSubscriptions();
      this.connection.addHandler(() => true, 'jabber:iq:roster', 'iq', 'set');
      this.connection.addHandler(
        message => {
          this.onMessageReceived(message);
        },
        null,
        'message',
        null,
        null,
        null
      );

      this.sessionId = 'session_id_set';
      this.connection.streamManagement.enable();
      this.connection.streamManagement.requestAcknowledgement();
      this.connected = true;
      this.events.connectionEstablishedRefresh();
    });
  }

  /**
   * @method handleSubscriptions Handle PubSub Subscriptions
   * @description
   * Retrieves the current JID PubSub node subscriptions
   * It does retrieve the bare JID subscriptions meaning it will retrieve even
   * subscriptions from other devices for the current bare JID.
   */
  handleSubscriptions() {
    this.connection.pubsub.getSubscriptions(async subscriptionsXML => {
      const subscriptions: Subscription[] = [];
      Strophe.forEachChild(subscriptionsXML.getElementsByTagName('subscriptions')[0], 'subscription', subscription => {
        const pubSubNodeId = subscription.getAttribute('node');
        const jid = subscription.getAttribute('jid');
        const subId = subscription.getAttribute('subid');
        if (pubSubNodeId) {
          subscriptions.push({ pubSubNodeId, jid, subId });
        }
      });
      this.subscriptionsSubject.next(subscriptions.slice());
    }, 10000);
  }

  /**
   * @method getCurrentJabberId Get current JID
   * @returns {string} JID
   */
  getCurrentJabberId(): string {
    return this.xmppConnection.jabberId;
  }

  onDisconnect() {
    this.connection = null;
    this.connected = false;
    this.sessionId = '';
  }

  /**
   * @method subscribeToNode Subscribes to a PubSub node
   * @param {string} pubSubNodeId PubSub node ID
   */
  subscribeToNode(pubSubNodeId: string) {
    this.connection.pubsub.subscribe(
      pubSubNodeId,
      null,
      message => {
        this.onMessageReceived(message);
        return true;
      },
      success => true,
      error => true
    );
  }

  /**
   * @method unsubscribeFromNode Unsubscribe from a PubSub node
   * @param {Subscription} subscription PubSub node subscription
   * @param {boolean} force Allow to unsubscribe subscriptions from other Full JID
   * This flag is used for where PubSubs no longer exist and we want to unsubscribe
   * sessions from other devices
   */
  unsubscribeFromNode(subscription: Subscription, force: boolean = false) {
    if (subscription.jid.toLowerCase() === this.xmppConnection.jabberId.toLowerCase() || force) {
      this.connection.pubsub.unsubscribe(
        subscription.pubSubNodeId,
        subscription.jid,
        subscription.subId || null,
        success => true,
        error => true
      );
    }
  }

  /**
   * @method isCurrentSessionSubscribedToNode Determine if the current JID is already subscribed to the PubSub node
   * @returns {boolean} True if already subscribed else false
   */
  isCurrentSessionSubscribedToNode(subscription: Subscription, pubSubNodeId: string): boolean {
    return (
      subscription.pubSubNodeId === pubSubNodeId &&
      subscription.jid.toLowerCase() === this.xmppConnection.jabberId.toLowerCase()
    );
  }

  onMessageReceived(message: any) {
    try {
      if (this.connection.handlers.filter(handler => handler.name === 'message').length < 1) {
        this.connection.addHandler(
          msg => {
            this.onMessageReceived(msg);
          },
          null,
          'message',
          null,
          null,
          null
        );
      }

      if (message.textContent == null || message.textContent.length <= 0) {
        return true;
      }

      if (message.textContent.startsWith('W')) {
        return true;
      }

      if (message.textContent.endsWith('Offline Storage')) {
        return true;
      }

      const jsonMessage = JSON.parse(message.textContent);

      if (jsonMessage.actionType === XMPPMessageAction.CloseAlert) {
        this.events.closeAlertReceivedRefresh(jsonMessage.alrtid);
      }

      if (jsonMessage.actionType === XMPPMessageAction.InitAlert) {
        this.events.initAlertReceivedRefresh();
      }

      if (jsonMessage.alrtid !== this.alertId) {
        return true;
      }

      if (jsonMessage.actionType === XMPPMessageAction.AlertMessage) {
        let isSchoolUser = true;
        if (jsonMessage.msgUsrType !== ChatUserType.SchoolStaff) {
          isSchoolUser = false;
        }
        jsonMessage.isSchoolUser = isSchoolUser;

        const dateCreatedLocal = this.dateService.formatMessageDate(jsonMessage.createdTime);

        jsonMessage.createdTime = dateCreatedLocal;

        this.events.messageReceivedRefresh(jsonMessage);
      }

      return true;
    } catch (err) {
      console.log(err);
      return true;
    }
  }

  initAlertMessage(alertId: number, alertGUID: string, location: Location, subject: string) {
    if (this.connection === null) {
      return false;
    }

    const alertDate = new Date().toISOString();

    const jsonBody = {
      actionType: XMPPMessageAction.InitAlert,
      msgId: alertGUID,
      alrtType: AlertType.Alert,
      sid: location.schoolId,
      sdid: this.user.schoolDistrictId,
      lt: location.Latitude || 0.0,
      ln: location.Longitude || 0.0,
      uid: this.user.uniqueId,
      name: `${this.user.displayName} [${location.location}]`,
      createdTime: alertDate,
      alrtid: alertId,
      msgUsrType: this.toChatUser(this.user),
      subject: subject
    };

    const payload = JSON.stringify(jsonBody);

    const guid = uuid();
    const xmppConnection = this.user.xmppConnection;

    const pub = $iq({ id: guid, type: 'set', to: 'pubsub.' + xmppConnection.domain, from: xmppConnection.jabberId })
      .c('pubsub', { xmlns: 'http://jabber.org/protocol/pubsub' })
      .c('publish', { node: xmppConnection.pubSubNodeId })
      .c('item', { id: guid })
      .c('x', { xmlns: 'jabber:x:data', type: 'result' })
      .c('field', { var: 'title' })
      .c('value')
      .t(payload);

    this.connection.sendIQ(
      pub,
      () => {
        this.events.initiateAlertRefresh(true);
      },
      () => {
        this.events.initiateAlertRefresh(false);
      },
      10000
    );
  }

  forwardAlertMessage(alertId: number, alertGUID: string, location: Location, subject: string) {
    if (this.connection === null) {
      return false;
    }

    const jsonBody = {
      actionType: XMPPMessageAction.ForwardAlert,
      msgId: alertGUID,
      alrtType: AlertType.Alert,
      sid: location.schoolId,
      sdid: this.user.schoolDistrictId,
      lt: location.Latitude || 0.0,
      ln: location.Longitude || 0.0,
      uid: this.user.uniqueId,
      name: `${this.user.displayName} [${location.location}]`,
      createdTime: new Date().toUTCString(),
      alrtid: alertId,
      msgUsrType: this.toChatUser(this.user),
      subject: subject
    };

    const payload = JSON.stringify(jsonBody);

    const guid = uuid();
    const xmppConnection = this.user.xmppConnection;

    const pub = $iq({ id: guid, type: 'set', to: 'pubsub.' + xmppConnection.domain, from: xmppConnection.jabberId })
      .c('pubsub', { xmlns: 'http://jabber.org/protocol/pubsub' })
      .c('publish', { node: xmppConnection.pubSubNodeId })
      .c('item', { id: guid })
      .c('x', { xmlns: 'jabber:x:data', type: 'result' })
      .c('field', { var: 'title' })
      .c('value')
      .t(payload);

    this.connection.sendIQ(
      pub,
      () => {
        this.events.initiateAlertRefresh(true);
      },
      () => {
        this.events.initiateAlertRefresh(false);
      },
      10000
    );
  }

  initReverseAlertMessage(reverseAlert: ReverseAlert) {
    if (this.connection === null) {
      return false;
    }

    const jsonBody = {
      aid: this.user.agencyId,
      actionType: XMPPMessageAction.InitAlert,
      msgId: reverseAlert.alertGuid,
      alrtType: AlertType.ReverseAlert,
      sid: 0,
      sdid: 0,
      sids: reverseAlert.schoolIds.join(),
      sdids: reverseAlert.schoolDistrictIds.join(),
      lt: 0.0,
      ln: 0.0,
      uid: this.user.uniqueId,
      name: this.user.displayName,
      createdTime: new Date().toUTCString(),
      alrtid: reverseAlert.alertId,
      msgUsrType: this.toChatUser(this.user),
      subject: reverseAlert.subject,
      msg: reverseAlert.message,
      hasNameChange: false,
      usrLstUpdated: false
    };

    const payload = JSON.stringify(jsonBody);

    const guid = uuid();
    const xmppConnection = this.user.xmppConnection;

    const pub = $iq({ id: guid, type: 'set', to: 'pubsub.' + xmppConnection.domain, from: xmppConnection.jabberId })
      .c('pubsub', { xmlns: 'http://jabber.org/protocol/pubsub' })
      .c('publish', { node: xmppConnection.pubSubNodeId })
      .c('item', { id: guid })
      .c('x', { xmlns: 'jabber:x:data', type: 'result' })
      .c('field', { var: 'title' })
      .c('value')
      .t(payload);

    this.connection.sendIQ(pub, success => {}, failure => {}, 10000);
  }

  closeAlertMessage(alertId: string, alertGUID: string, schoolId: number) {
    if (this.connection === null) {
      return false;
    }

    const jsonBody = {
      actionType: XMPPMessageAction.CloseAlert,
      aid: 0,
      alrtType: 0,
      alrtid: alertId,
      createdTime: null,
      hasNameChange: false,
      ln: 0,
      lt: 0,
      msg: null,
      msgId: '00000000-0000-0000-0000-000000000000',
      msgUsrType: this.toChatUser(this.user),
      name: null,
      sdid: 0,
      sdids: null,
      sid: 0,
      sids: null,
      subject: null,
      uid: 0,
      usrLstUpdated: false
    };

    const payload = JSON.stringify(jsonBody);

    const guid = uuid();
    const xmppConnection = this.user.xmppConnection;

    const pub = $iq({ id: guid, type: 'set', to: 'pubsub.' + xmppConnection.domain, from: xmppConnection.jabberId })
      .c('pubsub', { xmlns: 'http://jabber.org/protocol/pubsub' })
      .c('publish', { node: xmppConnection.pubSubNodeId })
      .c('item', { id: guid })
      .c('x', { xmlns: 'jabber:x:data', type: 'result' })
      .c('field', { var: 'title' })
      .c('value')
      .t(payload);

    this.connection.sendIQ(
      pub,
      () => {
        this.events.closeAlertRefresh(true);
      },
      () => {
        this.events.closeAlertRefresh(false);
      },
      10000
    );
  }

  sendMessage(message: Message) {
    if (!message.msg) {
      return;
    }

    const messageText = this.escapeSpecialChars(message.msg.trim());

    const jsonBody = {
      actionType: XMPPMessageAction.AlertMessage,
      uid: message.uid,
      name: message.name,
      createdTime: message.createdTime,
      alrtid: message.alrtid,
      msg: messageText,
      msgUsrType: this.toChatUser(this.user),
      msgId: message.msgId
    } as Message;

    const payload = JSON.stringify(jsonBody);

    const pub = $iq({
      id: message.msgId,
      type: 'set',
      to: 'pubsub.' + this.xmppConnection.domain,
      from: this.xmppConnection.jabberId
    })
      .c('pubsub', { xmlns: 'http://jabber.org/protocol/pubsub' })
      .c('publish', { node: this.xmppConnection.pubSubNodeId })
      .c('item', { id: message.msgId })
      .c('x', { xmlns: 'jabber:x:data', type: 'result' })
      .c('field', { var: 'title' })
      .c('value')
      .t(payload);

    this.connection.sendIQ(
      pub,
      // if message succeeds, save message to chat history
      () => {
        this.events.messageSentRefresh(jsonBody);
        
        const requestBody = {
          messageId: message.msgId,
          message: messageText,
          sendDate: message.createdTime,
          userId: message.uid
        };

        this.userService
          .getAuthToken()
          .then((token: string) => {
            const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
            this.http
              .post(`${environment.baseURL}/alerts/${message.alrtid}/messages`, requestBody, {
                headers
              })
              .subscribe({
                error: (err) => {
                  if (err.status === 401) {
                    this.uiAlertService.presentExpiredTokenAlert();
                  }
                }
              });
          })
          .catch(err => {
            console.log(err);
          });
      },
      this.onMessageFailure.bind(this),
      5000
    );
  }

  joinChat(user: User) {
    const jsonBody = {
      actionType: XMPPMessageAction.AlertMessage,
      uid: user.uniqueId,
      name: user.displayName,
      createdTime: new Date().toUTCString(),
      alrtid: this.alertId,
      msg: '',
      msgUsrType: this.toChatUser(user),
      msgId: uuid()
    };

    const payload = JSON.stringify(jsonBody);

    const pub = $iq({
      id: jsonBody.msgId,
      type: 'set',
      to: 'pubsub.' + this.xmppConnection.domain,
      from: this.xmppConnection.jabberId
    })
      .c('pubsub', { xmlns: 'http://jabber.org/protocol/pubsub' })
      .c('publish', { node: this.xmppConnection.pubSubNodeId })
      .c('item', { id: jsonBody.msgId })
      .c('x', { xmlns: 'jabber:x:data', type: 'result' })
      .c('field', { var: 'title' })
      .c('value')
      .t(payload);

    this.connection.sendIQ(
      pub,
      () => {},
      () => {
        this.presentAlert('Failed to join alert, please reload or send a message to try again.');
      },
      10000
    );
  }

  onMessageFailure(response) {
    this.presentAlert('Message failed to send, please try again.');
  }

  escapeSpecialChars(jsonString) {
    return jsonString
      .replace(/\\/g, '\\\\')
      .replace(/\r/g, '\\r')
      .replace(/\t/g, '\\t')
      .replace(/\f/g, '\\f')
      .replace(/"/g, '\\"');
  }

  toChatUser(user: User) {
    if (user.isDispatcher) {
      return ChatUserType.Dispatch;
    }

    switch (user.userTypeId) {
      case ApplicationUserType.InforceAdmin:
        return ChatUserType.None;

      case ApplicationUserType.AgencyAdmin:
        return ChatUserType.Officer;

      case ApplicationUserType.AgencyUser:
        return ChatUserType.Officer;

      case ApplicationUserType.SchoolDistrictAdmin:
        return ChatUserType.SchoolStaff;

      case ApplicationUserType.SchoolDistrictUser:
        return ChatUserType.SchoolStaff;

      case ApplicationUserType.SchoolAdmin:
        return ChatUserType.SchoolStaff;

      case ApplicationUserType.SchoolUser:
        return ChatUserType.SchoolStaff;

      case ApplicationUserType.Dispatcher:
        return ChatUserType.Dispatch;
    }
  }

  async presentAlert(message: string) {
    const alert = await this.alertController.create({
      message,
      header: 'Error',
      buttons: ['OK'],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }
}
