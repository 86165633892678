<section>
  <div class="input subject">
    <ion-label position="stacked">Subject <span class="required">*</span></ion-label>
    <ion-input
      maxlength="250"
      placeholder="Subject"
      (ionFocus)="onFocus()"
      (ionBlur)="onBlur()"
      [(ngModel)]="internalNotif.subject"
    ></ion-input>
  </div>
  <div class="input message">
    <ion-label position="stacked">Message</ion-label>
    <ion-textarea
      maxlength="2000"
      placeholder="Message"
      (ionFocus)="onFocus()"
      (ionBlur)="onBlur()"
      [(ngModel)]="internalNotif.message"
    ></ion-textarea>
  </div>

  <div *ngIf="!keyboardShowing" class="buttons" #buttons>
    <ion-button (click)="onCancel()" color="secondary">Cancel</ion-button>
    <ion-button [disabled]="!canSendInternalNotif" (click)="onClickSend()" color="danger">Send</ion-button>
  </div>
</section>
