import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AlertsPage } from './alerts.page';
import { AlertItemComponent } from 'src/app/components/alert-item/alert-item.component';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from 'src/app/shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AlertsPage,
    canActivate: [authGuard]
  }
];

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, RouterModule, RouterModule.forChild(routes)],
  declarations: [AlertsPage, AlertItemComponent]
})
export class AlertsPageModule {}
