import { Injectable } from '@angular/core';
import { Alert } from '../models/alert';

@Injectable({
  providedIn: 'root'
})
export class AlertDataService {
  private data = {};

  constructor() {}

  setData(alertId: number, data: Alert) {
    this.data[alertId] = data;
  }

  // Data is not accessed directly in pages using this function, see data-resolver.service
  getData(alertId: number) {
    const returnVal = this.data[alertId];
    // Data is deleted when retrieved so that it does not persist between pages and become stale.
    delete this.data[alertId];
    return returnVal;
  }
}
