import { Component } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AlertController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { observable, computed } from 'mobx-angular';

import { BaseUser } from '../../shared/models/user';
import { LoginService } from '../../shared/services/login.service';
import { LoadingService } from '../../shared/services/loading.service';
import { ApplicationService } from '../../shared/services/application.service';

@Component({
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  selector: 'app-forgot-password',
  templateUrl: 'forgot-password.page.html',
  styleUrls: ['../login/login.page.scss', 'forgot-password.page.scss']
})
export class ForgotPasswordPage {
  submitting = false;
  isOrgUser: boolean = this.applicationService.isOrgUser;

  @observable user: BaseUser = {
    uniqueId: '',
    agencyId: '',
    username: ''
  };

  @computed get formIsValid(): boolean {
    if (this.isOrgUser) {
      return this.user.uniqueId !== '' && this.user.username !== '';
    } else {
      return this.user.username !== '';
    }
  }

  constructor(
    private loadingService: LoadingService,
    private loginService: LoginService,
    private applicationService: ApplicationService,
    private alertController: AlertController,
    private platform: Platform,
    private router: Router
  ) {}

  submitForgotPassword(): void {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    this.loadingService.present();

    this.loginService
      .attemptForgotPassword(this.user, this.formIsValid)
      .then(() => {
        this.loadingService.dismiss();
        this.submitting = false;
        this.presentAlert(
          'Request Submitted',
          'Instructions to reset your password have been sent to your email address on file.<br />' +
            "<br />If you don't receive an email shortly, please contact your administrator."
        );
      })
      .catch((err: string) => {
        this.loadingService.dismiss();
        this.submitting = false;
        this.presentAlert('Error Submitting Request', err);
      });
  }

  login(): void {
    this.router.navigate(['/login'], { skipLocationChange: true });
  }

  async presentAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      message,
      header: header,
      buttons: [
        {
          text: 'Return to Login',
          handler: () => this.login()
        }
      ],
      mode: this.platform.is('ios') ? 'ios' : 'md',
      backdropDismiss: false
    });

    await alert.present();
  }
}
