<section *ngIf="schools !== null && schools.length > 0; else noLocations">
  <h2>Select Locations</h2>
  <div class="school-list">
    <div class="school">
      <div class="checkbox district-title">
        <ion-checkbox
          slot="start"
          color="blue"
          [(ngModel)]="allSchoolsSelected"
          (ionChange)="onSelectAllSchools($event)"
        ></ion-checkbox>
        <ion-label>All</ion-label>
      </div>
    </div>
    <div class="district" *ngFor="let schoolDistrict of schoolDistricts">
      <div class="checkbox district-title">
        <ion-checkbox
          slot="start"
          color="blue"
          [(ngModel)]="schoolDistrict.selected"
          (ionChange)="onSelectDistrict($event, schoolDistrict.districtId)"
        ></ion-checkbox>
        <ion-label class="text-ellipsis">{{ schoolDistrict.districtName }}</ion-label>
      </div>

      <div class="school" *ngFor="let school of schoolDistrict.schools">
        <div class="checkbox school-checkbox">
          <ion-checkbox
            slot="start"
            color="blue"
            [(ngModel)]="school.selected"
            (ionChange)="onSelectSchool($event, school.schoolId, schoolDistrict.districtId)"
          ></ion-checkbox>
          <ion-label class="text-ellipsis">{{ school.schoolName }}</ion-label>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons">
    <ion-button [disabled]="!atLeastOneSchoolSelected" (click)="onContinue()">Continue</ion-button>
  </div>
</section>
<ng-template #noLocations>
  <h2>No Locations Available</h2>
</ng-template>
