import { Component, ViewChild, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, MenuController, AlertController, Platform, ViewWillEnter, NavController, ViewWillLeave } from '@ionic/angular';
import { observable } from 'mobx-angular';
import { v4 as uuid } from 'uuid';
import * as datefns from 'date-fns';
import * as _ from 'lodash';

import { AlertsService } from 'src/app/shared/services/alerts.service';
import { Alert } from 'src/app/shared/models/alert';
import { ChatService } from 'src/app/shared/services/chat.service';
import { EventService } from 'src/app/shared/services/events.service';
import { LoadingService } from '../../shared/services/loading.service';
import { Message } from 'src/app/shared/models/message';
import { User } from 'src/app/shared/models/user';
import { Location } from 'src/app/shared/models/location'
import { UserService } from 'src/app/shared/services/user.service';
import { MessageListComponent } from 'src/app/components/message-list/message-list.component';
import { ChatUserType, ApplicationUserType, XMPPConnectionStatus, PlatformType } from 'src/app/shared/enums';
import { AlertParticipant } from 'src/app/shared/models/alertParticipant';
import { DateService } from 'src/app/shared/services/date.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { UIAlertService } from '../../shared/services/uiAlert.service';
import { environment } from 'src/environments/environment';
import { format, utcToZonedTime } from 'date-fns-tz';

// Page showing alert chatroom
@Component({
  selector: 'app-alert',
  templateUrl: './alert.page.html',
  styleUrls: ['./alert.page.scss']
})
export class AlertPage implements AfterViewInit, ViewWillEnter, ViewWillLeave {
  participants = {
    dispatchUsers: [] as AlertParticipant[],
    officerUsers: [] as AlertParticipant[],
    locationUsers: [] as AlertParticipant[]
  };
  messages: Message[] = [];
  @observable messageText: any = null;
  alertClosed = false;
  user: User | null = null;
  alertId: number | null = null;
  alertGUID: string | null = null;
  location: Location | null = null;
  isParticipant = false;
  alert: Alert | null = null;
  isOrgUser = this.applicationService.isOrgUser;
  isInternalAdmin = null;
  canCloseAlert = false;
  userGroupsLE: String[] = [];
  userGroupsInternal: String[] = [];

  validateTokenTime = 10000;
  validateTokenInterval: any = 10000;

  constructor(
    private loadingService: LoadingService,
    private alertsService: AlertsService,
    private chatService: ChatService,
    private userService: UserService,
    private dateService: DateService,
    private loginService: LoginService,
    private applicationService: ApplicationService,
    private uiAlertService: UIAlertService,
    private route: ActivatedRoute,
    private events: EventService,
    private router: NavController,
    private menu: MenuController,
    private alertController: AlertController,
    private platform: Platform
  ) {}

  @ViewChild('content') content: IonContent | null = null;
  @ViewChild(MessageListComponent) messageListComponent: MessageListComponent | null = null;

  ngAfterViewInit() {
    if(!this.messageListComponent || !this.content) return;
    this.messageListComponent.messageList.changes.subscribe(() => {
      this.content.scrollToBottom();
    });
  }

  ionViewWillEnter() {
    this.loadingService.present();

    if (this.route.snapshot.data['alert']) {
      this.alert = this.route.snapshot.data['alert'];
    }
    this.alertId = parseInt(this.route.snapshot.params['alertId'], 10);

    this.events.tabsChangedRefresh(false);

    this.events.connectionEstablishedSource$.subscribe(this.onXmppConnectionEstablished.bind(this));
    this.events.closeAlertReceivedSource$.subscribe(this.onCloseAlertReceived.bind(this));
    this.events.messageReceivedSource$.subscribe(this.displayMessage.bind(this));
    this.events.messageSentSource$.subscribe(this.displayMessage.bind(this));

    if (this.chatService.getConnectionStatus() === XMPPConnectionStatus.CONNECTED) {
      this.onXmppConnectionEstablished();
    }
    this.chatService.attemptConnection();

    // Validate session on an interval to ensure user's session remains valid during chat
    this.validateTokenInterval = setInterval(() => {
      this.loginService
        .validate()
        .then(success => {})
        .catch((err: any) => {
          /** Stop the timer, close the connection, and unsubscribe if the auth token is expired */
          if (err.status === 401) {
            clearInterval(this.validateTokenInterval);
            this.chatService.closeConnection();
            this.uiAlertService.presentExpiredTokenAlert();
          }
        });
    }, this.validateTokenTime);
  }

  ionViewWillLeave() {
    this.chatService.setAlertId(null);

    this.participants = {
      dispatchUsers: [] as AlertParticipant[],
      officerUsers: [] as AlertParticipant[],
      locationUsers: [] as AlertParticipant[]
    };
    this.messages = [] as Message[];
    this.messageText = null;
    this.alertClosed = false;
    this.user = null;
    this.alertId = null;
    this.isParticipant = false;
    this.alert = null;

    clearInterval(this.validateTokenInterval);

    this.events.tabsChangedRefresh(true);
  }

  onXmppConnectionEstablished() {
    if(this.alertId === null) {
      this.loadingService.dismiss();
      return;
    }

    Promise.all([
      this.userService.getUser(),
      this.alert ? null : this.alertsService.getAlertDetails(this.alertId),
      this.alertsService.getChatHistory(this.alertId)
    ])
      .then((values: any) => {

        this.user = values[0];
        this.isInternalAdmin = this.user.canManageInternalNotifs;
        if (this.isInternalAdmin) {
          this.userGroupsLE = ["Active Shooter", "Intruder", "Medical", "Other"];
          this.userGroupsInternal = ["All Staff"];
        }
        this.chatService.setAlertId(this.alertId);

        this.alert = values[1] ? values[1] : this.alert;
        const alertParticipants = values[2].participants;
        this.canCloseAlert =
          (this.user.canCloseAlerts && !this.alert.isReverseAlert) ||
          (this.user.canCloseReverseAlerts && this.alert.isReverseAlert) ||
          (this.isInternalAdmin && this.alert.isInternal);

        if (this.alert.isReverseAlert && this.alert.message) {
          const alertCreator = _.find(alertParticipants, participant => {
            return (participant.userId = this.alert.userId);
          });
          const reverseAlertMessage: Message = {
            alrtid: parseInt(this.alert.alertId, 10),
            actionType: null,
            createdTime: this.dateService.formatMessageDate(new Date(this.alert.createdDate)),
            isSchoolUser: false,
            msg: this.alert.message,
            msgId: this.alert.alertGuid,
            name: this.alert.userName,
            uid: this.alert.userId.toString(),
            msgUsrType: alertCreator ? alertCreator.userTypeId : ApplicationUserType.AgencyUser
          };
          this.messages.push(reverseAlertMessage);
        }
        this.messages = _.concat(this.messages, values[2].messages);

        const participantIds = _.map(values[2].participants, 'userId');
        this.isParticipant = participantIds.indexOf(this.user.uniqueId) !== -1;

        if (!this.isParticipant) {
          this.joinAlert();
        }

        this.participants.dispatchUsers = alertParticipants.filter((user: AlertParticipant) => user.isDispatcher);

        this.participants.locationUsers = alertParticipants.filter((user: AlertParticipant) => {
          switch (user.userTypeId) {
            case ApplicationUserType.SchoolAdmin:
            case ApplicationUserType.SchoolDistrictAdmin:
            case ApplicationUserType.SchoolDistrictUser:
            case ApplicationUserType.SchoolUser:
              return true;
            default:
              return false;
          }
        });

        this.participants.officerUsers = alertParticipants.filter((user: AlertParticipant) => {
          return (
            !user.isDispatcher &&
            (user.userTypeId === ApplicationUserType.AgencyAdmin || user.userTypeId === ApplicationUserType.AgencyUser)
          );
        });

        this.sortParticipants();
      })
      .catch(err => {
        console.log('onXmppConnectionEstablished err', err);
      })
      .finally(() => {
        this.loadingService.dismiss();
      });
  }

  sortParticipants(): void {
    this.participants.dispatchUsers = this.participants.dispatchUsers.sort(this.compareParticipants);
    this.participants.locationUsers = this.participants.locationUsers.sort(this.compareParticipants);
    this.participants.officerUsers = this.participants.officerUsers.sort(this.compareParticipants);
  }

  compareParticipants(a: AlertParticipant, b: AlertParticipant): number {
    if (a.userName < b.userName) {
      return -1;
    }
    return 1;
  }

  forwardLE(title: String) {
    this.location = this.user.defaultSchool;
    this.alertsService
    .forwardToLawEnforcement(this.alertId, title, this.alert.alertGuid)
    .then(
      ({ alertId, alertGUID }) => {

        if (this.chatService.getConnectionStatus() === XMPPConnectionStatus.CONNECTED) {
          this.chatService.setAlertId(this.alertId);
        }
        this.chatService.attemptConnection();
        this.chatService.forwardAlertMessage(alertId, alertGUID, this.location, this.alert.subject);
      }
    );
  }

  forwardInternal(group: String) {
    this.location = this.user.defaultSchool;
    this.alertsService
    .forwardToUserGroup(this.alertId, group, 0, this.alert.alertGuid)
    .then(({ alertId, alertGUID }) => {
        this.alertId = alertId;

        if (this.chatService.getConnectionStatus() === XMPPConnectionStatus.CONNECTED) {
          this.chatService.setAlertId(this.alertId);
        }
        this.chatService.attemptConnection();
        this.chatService.forwardAlertMessage(alertId, alertGUID, this.location, this.alert.subject);
      }
    );
    
  }

  sendAlertMessage() {
    this.chatService.initAlertMessage(this.alertId, this.alert.alertGuid, this.location, this.alert.subject);
  }

  addUserToParticipants(message: Message): void {
    if (
      this.participants.dispatchUsers.some(p => p.userId === parseInt(message.uid, 10)) ||
      this.participants.officerUsers.some(p => p.userId === parseInt(message.uid, 10)) ||
      this.participants.locationUsers.some(p => p.userId === parseInt(message.uid, 10))
    ) {
      return;
    }

    if (message.msgUsrType === ChatUserType.Dispatch) {
      this.participants.dispatchUsers.push({
        isDispatcher: true,
        userId: parseInt(message.uid, 10),
        userName: message.name
      });
    } else if (message.msgUsrType === ChatUserType.Officer) {
      this.participants.officerUsers.push({
        isDispatcher: false,
        userId: parseInt(message.uid, 10),
        userName: message.name
      });
    } else if (message.msgUsrType === ChatUserType.SchoolStaff) {
      this.participants.locationUsers.push({
        isDispatcher: false,
        userId: parseInt(message.uid, 10),
        userName: message.name
      });
    }

    this.sortParticipants();
  }

  onSetMessageText(text: string): void {
    this.messageText = text;
  }

  onSendMessage(): void {
    const message: Message = {
      alrtid: this.alertId,
      actionType: null,
      createdTime: new Date().toISOString(),
      isSchoolUser: true,
      msg: this.messageText,
      msgId: uuid(),
      name: this.user.displayName,
      uid: this.user.uniqueId,
      msgUsrType: this.user.userTypeId
    };

    this.messageText = '';
    this.chatService.sendMessage(message);
  }

  displayMessage(message: Message) {
    this.addUserToParticipants(message);

    if (!message.msg) {
      return;
    }

    const messageExists = this.messages.find(msg => {
      return msg.msgId === message.msgId;
    });

    if (!messageExists) {
      message.createdTime = this.dateService.formatMessageDate(new Date(message.createdTime));

      this.messages.push(message);
    }
  }

  onCloseAlertReceived(alertId: string) {
    if (this.alertId === parseInt(alertId, 10)) {
      this.alertClosed = true;
    }
  }

  joinAlert() {
    this.alertsService.joinAlert(this.alertId, this.user.uniqueId).then(success => {
      if (success) {
        this.chatService.joinChat(this.user);
      }

      this.isParticipant = success;
      if (
        this.isParticipant &&
        (this.user.userTypeId === ApplicationUserType.SchoolDistrictAdmin ||
          this.user.userTypeId === ApplicationUserType.SchoolDistrictUser ||
          this.user.userTypeId === ApplicationUserType.SchoolAdmin ||
          this.user.userTypeId === ApplicationUserType.SchoolUser)
      ) {
        this.participants.locationUsers.push({
          isDispatcher: false,
          userId: parseInt(this.user.uniqueId, 10),
          userName: this.user.displayName
        });
      } else if (this.isParticipant && this.user.isDispatcher) {
        this.participants.dispatchUsers.push({
          isDispatcher: true,
          userId: parseInt(this.user.uniqueId, 10),
          userName: this.user.displayName
        });
      } else if (this.isParticipant) {
        this.participants.officerUsers.push({
          isDispatcher: false,
          userId: parseInt(this.user.uniqueId, 10),
          userName: this.user.displayName
        });
      }
    });
  }

  goToAlertList(): void {
    this.router.navigateBack(['tabs', 'alerts']);
  }

  goToFile(filePath: string): void {
    if (environment.platformType == PlatformType.Browser) {
      window.open(filePath, '_blank');
    }
    else
    {
      window.open(filePath, '_self');
    }

  }

  showUserList(): void {
    // A bug occurs when navigating from new alert to alert chat. The participants list will no longer open
    //   for any other alert. The bug is caused by the participants menu not getting removed from the array
    //   of menus and remaining in an 'enabled' state.
    // The participants menu we want to open will be the last menu in the array of menus, so to work around
    //   this bug we disable all menus except for the last menu, which we enable, and then call open().
    this.menu.getMenus().then(menus => {
      for (let i = 0; i < menus.length - 1; i++) {
        menus[i].disabled = true;
      }
      menus[menus.length - 1].disabled = false;
      this.menu.open();
    });
  }

  async presentCloseAlertConfirmation() {
    const alert = await this.alertController.create({
      message: 'Are you sure you want to close this alert?',
      header: 'Confirmation',
      buttons: [
        'Cancel',
        {
          text: 'Close Alert',
          handler: () => {
            this.loadingService.present();
            this.alertsService
              .closeAlert(this.alertId)
              .then(() => {
                this.loadingService.dismiss();
                this.alertClosed = true;
                this.chatService.closeAlertMessage(this.alert.alertId, this.alert.alertGuid, this.alert.schoolId);
              })
              .catch((err: any) => {
                this.loadingService.dismiss();
                this.presentFailedClosedAlert();
              });
          }
        }
      ],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }

  async presentForwardConfirmationInternal(label) {
    this.location = this.user.defaultSchool;
    const alert = await this.alertController.create({
      message: 'Are you sure you want to forward to '+label+'?',
      header: 'Confirmation',
      buttons: [
        'Cancel',
        {
          text: 'Forward',
          handler: () => {
            this.loadingService.present();
            this.alertsService
              .forwardToUserGroup(this.alertId, label, 0, this.alert.alertGuid)
              .then(({ alertId, alertGUID }) => {
                //this.alertId = alertId;
                //this.alertGUID = alertGUID;
        
                if (this.chatService.getConnectionStatus() === XMPPConnectionStatus.CONNECTED) {
                  this.chatService.setAlertId(this.alertId);
                }
                this.chatService.attemptConnection();
                this.chatService.forwardAlertMessage(this.alertId, this.alert.alertGuid, this.location, this.alert.subject);
              }
            )
              .then(() => {
                //window.location.reload();
                this.loadingService.dismiss();
              })
              .catch((err: any) => {
                this.loadingService.dismiss();
                this.presentFailedClosedAlert();
              });
          }
        }
      ],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }

  async presentForwardConfirmationLE(label) {
    this.location = this.user.defaultSchool;
    const alert = await this.alertController.create({
      message: 'Are you sure you want to forward to '+label+'?',
      header: 'Confirmation',
      buttons: [
        'Cancel',
        {
          text: 'Forward',
          handler: () => {
            this.loadingService.present();
            this.alertsService
              .forwardToLawEnforcement(this.alertId, label, this.alert.alertGuid)
              .then(({ alertId, alertGUID }) => {
                //this.alertId = alertId;
                //this.alertGUID = alertGUID;
        
                if (this.chatService.getConnectionStatus() === XMPPConnectionStatus.CONNECTED) {
                  this.chatService.setAlertId(this.alertId);
                }
                this.chatService.attemptConnection();
                this.chatService.forwardAlertMessage(this.alertId, this.alert.alertGuid, this.location, this.alert.subject);
              }
            )
              .then(() => {
                //window.location.reload();
                this.loadingService.dismiss();
              })
              .catch((err: any) => {
                this.loadingService.dismiss();
                this.presentFailedClosedAlert();
              });
          }
        }
      ],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }

  async presentFailedClosedAlert() {
    const alert = await this.alertController.create({
      message: 'Failed to close alert. Please try again.',
      header: 'Error',
      buttons: ['OK'],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }
}
