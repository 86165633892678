import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, Platform, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { EventService } from 'src/app/shared/services/events.service';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';

import { environment } from '../../../environments/environment';
import { School } from 'src/app/shared/models/school';
import { Location } from 'src/app/shared/models/location';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { LoadingService } from '../../shared/services/loading.service';
import { User } from 'src/app/shared/models/user';
import { Message } from '../../shared/models/message';
import { XMPPConnectionStatus } from 'src/app/shared/enums';

@Component({
  selector: 'app-new-alert',
  templateUrl: './new-alert.page.html',
  styleUrls: ['./new-alert.page.scss']
})
export class NewAlertPage implements ViewWillEnter, ViewWillLeave {
  school: School = null;
  location: Location = null;
  alertId: number = null;
  user: User = null;
  alertGUID: string = null;
  timer = null;
  currentTime = null;
  timerPercentage = 100;
  viewLoaded = false;
  isInternal: Boolean = null;
  title: string;
  message: string;

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private alertsService: AlertsService,
    private chatService: ChatService,
    private alertController: AlertController,
    private platform: Platform,
    private callNumber: CallNumber,
    private events: EventService
  ) {}

  ionViewWillEnter() {
    this.events.tabsChangedRefresh(false);
    this.school = window.history.state.school;
    this.location = window.history.state.location;
    this.user = window.history.state.user;
    this.isInternal = window.history.state.isInternal;
    this.title = window.history.state.title;
    this.message = window.history.state.message;
    this.startTimer();

    this.events.connectionEstablishedSource$.subscribe(this.sendAlertMessage.bind(this));
    this.events.initiateAlertSource$.subscribe(this.alertCreatedResponse.bind(this));
    this.viewLoaded = true;
  }

  ionViewWillLeave() {
    clearInterval(this.timer);
    this.events.tabsChangedRefresh(true);
    this.viewLoaded = false;
  }

  startTimer(): void {
    const start = Date.now();
    const timerLength = 10;
    this.currentTime = timerLength;

    this.timer = setInterval(() => {
      if (this.currentTime < 1) {
        this.confirmAlert();
      } else {
        const delta = Date.now() - start;
        this.currentTime = timerLength - Math.floor(delta / 1000);
        this.timerPercentage = Math.floor((this.currentTime / timerLength) * 100);
      }
    }, 100); // check the delta between the start time and the current time every 100ms
  }

  confirmAlert(): void {
    clearInterval(this.timer);
    /*const firstMessage: Message = 
    {
      alrtid: this.alertId,
      actionType: 3,
      createdTime: moment()
      .utc()
      .format(),
      isSchoolUser: true,
      msg: this.message,
      msgId: uuid(),
      name: this.user.displayName,
      uid: this.user.uniqueId,
      msgUsrType: this.user.userTypeId
    }*/
    this.loadingService.present();
    if (this.isInternal) {
      this.alertsService
      .startNewInternalNotif(this.user, this.location, this.school, this.title)
      .then(({ alertId, alertGUID }) => {
        if (alertId === 0) {
          this.loadingService.dismiss();
          this.presentFailedAlertCreationAlert();
        } else {
          this.alertId = alertId;
          this.alertGUID = alertGUID;

          if (this.chatService.getConnectionStatus() === XMPPConnectionStatus.CONNECTED) {
            this.chatService.setAlertId(this.alertId);
            
            this.sendAlertMessage(null);
          }
          this.chatService.attemptConnection();
        }
      })
      .then( () => {
        if (this.user.canManageInternalNotifs == true)
        {
          this.alertsService.forwardToUserGroup(this.alertId, this.title, 0, this.alertGUID)
          this.chatService.forwardAlertMessage(this.alertId, this.alertGUID, this.location, this.title)
        }
        
      })
      .catch((err: any) => {
        this.loadingService.dismiss();
        if (err.status !== 401) {
          this.presentFailedAlertCreationAlert();
        }
      });
    }
    else {
      this.alertsService
      .startNewAlert(this.user, this.location, this.school, this.title)
      .then(({ alertId, alertGUID }) => {
        if (alertId === 0) {
          this.loadingService.dismiss();
          this.present991Alert();
        } else {
          this.alertId = alertId;
          this.alertGUID = alertGUID;

          if (this.chatService.getConnectionStatus() === XMPPConnectionStatus.CONNECTED) {
            this.chatService.setAlertId(this.alertId);
            this.sendAlertMessage(null);
          }
          this.chatService.attemptConnection();
        }
      })
      .catch((err: any) => {
        this.loadingService.dismiss();
        if (err.status !== 401) {
          this.present991Alert();
        }
      });
    }
    
  }

  startCall() {
    this.callNumber.callNumber(environment.contactNumber, true).catch(err => console.log(err));
  }

  goToLocations(): void {
    clearInterval(this.timer);
    
    this.events.tabsChangedRefresh(true);

    this.router.navigate(['/tabs/categories']);
  }

  sendAlertMessage(firstMessage) {
    this.chatService.initAlertMessage(this.alertId, this.alertGUID, this.location, this.title);
    if (firstMessage != null)
      this.chatService.sendMessage(firstMessage);
  }

  alertCreatedResponse(success) {
    this.loadingService.dismiss();
    if (success) {
      this.router.navigate(['tabs/alerts/' + this.alertId]);
    } else {
      this.present991Alert();
    }
  }

  async present991Alert() {
    const alert = await this.alertController.create({
      message: 'No agency users are logged in. Call 9-1-1.',
      header: 'Error',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Call 9-1-1',
          handler: () => this.startCall()
        }
      ],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }

  async presentFailedAlertCreationAlert() {
    const alert = await this.alertController.create({
      message: 'The alert has failed to be created, call 9-1-1 if this is an emergency.',
      header: 'Error',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Call 9-1-1',
          handler: () => this.startCall()
        }
      ],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }

  async presentAlert(message: string, header?: string) {
    const alert = await this.alertController.create({
      message,
      header: header || 'Error',
      buttons: ['OK'],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }
}
