import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password.page';
import { loginGuard } from './shared/guards/login.guard';
import { ApplicationService } from 'src/app/shared/services/application.service';

const applicationService = new ApplicationService(null);
// Literal in place of applicationService.isOrgUser, can't use dynamically loaded class information in Angular templates
const applicationServiceIsOrgUser = true;

const routes: Routes = [
  { path: 'login', component: LoginPage, canActivate: [loginGuard] },
  { path: 'forgot-password', component: ForgotPasswordPage },
  {
    path: '',
    redirectTo: applicationServiceIsOrgUser ? '/tabs/categories' : '/tabs/new-reverse-alert',
    pathMatch: 'full'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
