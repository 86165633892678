import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

import { environment } from '../../../environments/environment';
import { UserService } from './user.service';
import { Alert } from '../models/alert';
import { DateService } from './date.service';
import { User } from '../models/user';
import { School } from '../models/school';
import { UIAlertService } from './uiAlert.service';
import { Resource } from '../models/resource';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private dateService: DateService,
    private uiAlertService: UIAlertService
  ) {}

  getUserResources() {
    return new Promise<Resource[]>((resolve, reject) => {
      this.userService.getAuthToken().then((token: string) => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        this.http.get(`${environment.baseURL}/accounts/resources`, {
            headers
          })
          .subscribe(
            (response: any) => {
              if (response.responseSummary.statusCode !== 0) {
                reject(response.responseSummary.errorReason);
              } else if (response.resources == null) {
                resolve(null);
              } else if (response.resources.length === 0) {
                resolve(response.resources);
              }

              resolve(response.resources);
            },
            failure => {
              if (failure.status === 401) {
                this.uiAlertService.presentExpiredTokenAlert();
              }
              reject(failure);
            }
          );
      });
    });
  }

}
