import { Component, Input, ViewChildren, QueryList } from '@angular/core';
import { Message } from 'src/app/shared/models/message';

@Component({
  selector: 'message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent {
  @Input() messages: Message[] | null = null;

  @ViewChildren('messageList') messageList: QueryList<Message> | null = null;

  constructor() {}
}
