import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AlertPage } from './alert.page';
import { MessageInputComponent } from 'src/app/components/message-input/message-input.component';
import { AlertDetailsComponent } from 'src/app/components/alert-details/alert-details.component';
import { MessageListComponent } from 'src/app/components/message-list/message-list.component';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from 'src/app/shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AlertPage,
    canActivate: [authGuard]
  }
];

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, RouterModule, RouterModule.forChild(routes)],
  declarations: [AlertPage, MessageInputComponent, MessageListComponent, AlertDetailsComponent]
})
export class AlertPageModule {}
