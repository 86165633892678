<ion-header>
  <ion-toolbar>
    <ion-title>
      Active Alerts
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="alerts.length > 0; else noAlerts">
    <article>
      <ng-container *ngFor="let alert of alerts">
        <alert-item [alert]="alert" (goToAlert)="onGoToAlert(alert)"></alert-item>
      </ng-container>
    </article>
  </ng-container>
  <ng-template #noAlerts>
    <p class="no-alerts">No active alerts.</p>
  </ng-template>
</ion-content>
