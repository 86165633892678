import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading = false;

  constructor(public controller: LoadingController) {}

  async present(loadingMessage?: string) {
    this.loading = true;
    return await this.controller
      .create({
        spinner: 'lines',
        message: loadingMessage,
        duration: 30000
      })
      .then(indicator => {
        indicator.present().then(() => {
          if (!this.loading) {
            indicator.dismiss();
          }
        });
      });
  }

  async dismiss() {
    this.loading = false;
    return await this.controller.getTop().then((indicator) => {
      indicator ? this.controller.dismiss() : false;
    });
  }
}
