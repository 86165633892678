import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TabsPageModule } from './pages/tabs/tabs.module';
import { ForgotPasswordPageModule } from './pages/forgot-password/forgot-password.module';
import { LoginPageModule } from './pages/login/login.module';
import { EventService } from './shared/services/events.service';
import { Drivers } from '@ionic/storage';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { PlatformType } from './shared/enums';
import { StorageService, createStorageService } from './shared/services/storage.service';
import { NotificationsService, createNotificationService } from './shared/services/notifications.service';
import { PushNotificationService } from './shared/services/push-notification.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'ios', hardwareBackButton: false, swipeBackEnabled: false }),
    LoginPageModule,
    ForgotPasswordPageModule,
    TabsPageModule,
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      driverOrder: [Drivers.SecureStorage, Drivers.LocalStorage, Drivers.IndexedDB]
    }),
    ServiceWorkerModule.register(`firebase-messaging-sw.js`,
      {
        enabled: environment.platformType === PlatformType.Browser,
        scope: `/`
      }
    ),
  ],
  providers: [
    CallNumber,
    PushNotificationService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: createStorageService,
      deps: [StorageService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: createNotificationService,
      deps: [NotificationsService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
