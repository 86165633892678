import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { computed } from 'mobx-angular';
import { Alert } from 'src/app/shared/models/alert';
import { KeyboardService } from 'src/app/shared/services/keyboard.service';

@Component({
  selector: 'categories-internal-details',
  templateUrl: './categories-internal-details.component.html',
  styleUrls: ['./categories-internal-details.component.scss'],
})
export class CategoriesInternalDetailsComponent implements OnInit {

  @Input() internalNotif: Alert| undefined = undefined;
  @Output() cancel = new EventEmitter<boolean>();
  @Output() clickSend = new EventEmitter();
  //@Output() clearAllDetails = new EventEmitter();

  constructor(private keyboardService: KeyboardService) {}

  ngOnInit() {
    //this.clearAllDetails.emit();
  }

  @computed get canSendInternalNotif() {
    if(!this.internalNotif) return "";
    return this.internalNotif.subject.trim();
  }

  @computed get keyboardShowing() {
    return this.keyboardService.keyboardShowing;
  }

  onFocus(): void {
    this.keyboardService.setKeyboardShowing(true);
  }

  onBlur(): void {
    this.keyboardService.setKeyboardShowing(false);
  }

  onCancel(): void {
    this.cancel.emit();
  }

  onClickSend(): void {
    if(!this.internalNotif) return;
    this.clickSend.emit({title: this.internalNotif.subject, message: this.internalNotif.message});
  }

}
