import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SettingsPage } from './settings.page';
import { LogoutComponent } from 'src/app/components/logout/logout.component';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from 'src/app/shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: SettingsPage,
    canActivate: [authGuard]
  }
];


@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, RouterModule, RouterModule.forChild(routes)],
  declarations: [SettingsPage, LogoutComponent]
})
export class SettingsPageModule {}
