import { Injectable } from '@angular/core';

import { DeviceData } from '../models/deviceData';
import { XMPPConnection, XMPPUser } from '../models/xmppConnection';
import { User } from '../models/user';
import { UserRaw } from '../models/userRaw';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private storage: StorageService, private http: HttpClient) {

  }

  getDeviceData(): Promise<DeviceData> {
    return Promise.all([
      this.storage.get('deviceCode'),
      this.storage.get('deviceKey'),
      this.storage.get('deviceIdentifier')
    ]).then(values => {
      const deviceCode = values[0];
      const deviceKey = values[1];
      const deviceIdentifier = values[2];

      return {
        deviceCode,
        deviceKey,
        deviceIdentifier
      };
    });
  }

  setDeviceIdentifier(deviceIdentifier: string) {
    this.storage.set('deviceIdentifier', deviceIdentifier);
  }

  setDeviceCode(deviceCode: string) {
    this.storage.set('deviceCode', deviceCode);
  }

  setDeviceKey(deviceKey: string) {
    this.storage.set('deviceKey', deviceKey);
  }

  getAuthToken(): Promise<string> {
    return this.storage.get('authToken').then((token: string) => {
      return token;
    });
  }

  setAuthToken(authToken: string): Promise<void> {
    return this.storage.set('authToken', authToken);
  }

  setSessionToken(token: string): void {
    localStorage.setItem('sessionToken', token);
  }

  getSessionToken(): string | null {
    return localStorage.getItem('sessionToken');
  }

  getAcceptedEULA(): Promise<boolean> {
    return this.storage.get('acceptedEULA');
  }

  setAcceptedEULA(accepted: boolean): void {
    this.storage.set('acceptedEULA', accepted);
  }

  getUser(): Promise<User> {
    return this.storage.get('user').then((user: User) => {
      return user;
    });
  }

  setUserRaw(user: UserRaw, data: DeviceData): Promise<void> {
    // takes the raw user data and saves it as a User model
    return this.storage.set('user', {
      uniqueId: user.UserID,
      agencyId: user.AgencyID,
      username: user.UserName,
      displayName: user.DisplayName,
      userTypeId: user.UserTypeId,
      isDispatcher: user.IsDispatcher,
      schoolDistrictId: user.SchoolDistID,
      defaultSchoolId: user.SchoolID,
      defaultSchool:
      {
        locationId: user.CurrentLocationID,
        location: user.CurrentLocationName,
        schoolId: user.SchoolID,
        longitude: 0,
        latitude: 0,
        selected: 0
      },
      canCloseAlerts: user.IsEndAlert,
      canCloseReverseAlerts: user.IsEndReverseAlert,
      canManageInternalNotifs: user.IsInternalAdmin,
      xmppConnection: {
        pubSubNodeId: user.PubsNodeId,
        jabberId: `${user.JabberId}@${user.XMPPDomain}/${data.deviceIdentifier}`,
        domain: user.XMPPDomain,
        boshUrl: 'https://' + user.XMPPDomain + ':5280/http-bind',
        password: user.XMPPPassword
      }
    });
  }

  clearUserData() {
    return new Promise((resolve, reject) => {
      Promise.all([this.storage.remove('user'), this.storage.remove('authToken')])
        .then(values => {
          resolve(values);
        })
        .catch((err: any) => {
          reject();
        });
    }).catch((err: any) => {
      return;
    });
  }

  getXMPPConnection(): Promise<XMPPConnection> {
    return this.storage.get('user').then((user: User) => {
      return user.xmppConnection;
    });
  }

  getUserLocations(userId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getAuthToken().then((token: string) => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        this.http.get(`${environment.baseURL}/accounts/locations/${userId}`, { headers }).subscribe(
          (response: any) => {
            resolve(response);
          },
          failure => {
            reject(failure);
          }
        );
      });
    });
  }
}
