import { Component, Input, Output, EventEmitter } from '@angular/core';
import { computed } from 'mobx-angular';

import { Alert } from 'src/app/shared/models/alert';
import { DateService } from 'src/app/shared/services/date.service';

@Component({
	selector: 'alert-item',
	templateUrl: './alert-item.component.html',
	styleUrls: ['./alert-item.component.scss']
})
export class AlertItemComponent {
	@Input() alert: Alert | undefined = undefined;
	@Output() goToAlert = new EventEmitter();
	maxDays = 99;

	constructor(private dateService: DateService) { }

	@computed get timeStarted() {
		if (!this.alert) return '';
		return this.dateService.formatStartDate(new Date(this.alert.createdDate + "Z"));
	}

	@computed get timeSinceCreated() {
		if (!this.alert) return '';
		return this.dateService.timeElapsedSinceDate(new Date(this.alert.createdDate + "Z"), this.maxDays);
	}

	onGoToAlert() {
		this.goToAlert.emit();
	}
}
