import { Injectable, Optional } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApplicationVersion, PlatformType, DeviceType } from '../enums';
import { Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  isOrgUser: boolean = environment.applicationVersion === ApplicationVersion.Organization;
  isBrowser: boolean = environment.platformType === PlatformType.Browser;
  constructor(@Optional() private platform: Platform) {}

  getDeviceType(): DeviceType {
    if (this.platform.is('ios')) {
      return DeviceType.iPhone;
    }

    if (this.platform.is('android')) {
      return DeviceType.Android;
    }

    if (this.platform.is('ipad')) {
      return DeviceType.iPad;
    }

    if (environment.platformType === PlatformType.Browser) {
      return DeviceType.Browser;
    }

    return DeviceType.Windows;
  }
}
