import { Component } from '@angular/core';
import { AlertController, NavController, Platform, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { EventService } from 'src/app/shared/services/events.service';
import { Router } from '@angular/router';

import { ReverseAlert } from 'src/app/shared/models/reverseAlert';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { SchoolService } from 'src/app/shared/services/school.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AgencySchool } from 'src/app/shared/models/school';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SchoolDistrictsList } from 'src/app/shared/models/schoolDistrictsList';
import { SchoolDistrict } from 'src/app/shared/models/schoolDistrict';
import { ChatService } from 'src/app/shared/services/chat.service';
import { XMPPConnectionStatus } from 'src/app/shared/enums';

@Component({
  selector: 'new-reverse-alert',
  templateUrl: './new-reverse-alert.page.html',
  styleUrls: ['./new-reverse-alert.page.scss']
})
export class NewReverseAlertPage implements ViewWillEnter, ViewWillLeave {
  reverseAlert: ReverseAlert = {
    message: '',
    subject: '',
    schoolIds: [],
    schoolDistrictIds: [],
    alertId: 0,
    alertGuid: ''
  };

  selectingSchools = true;
  allSchoolsSelected = false;
  schools: AgencySchool[] = [];
  schoolDistricts: SchoolDistrict[] = [];
  startAlert = false;

  constructor(
    private alertController: AlertController,
    private loadingService: LoadingService,
    private platform: Platform,
    private schoolService: SchoolService,
    private userService: UserService,
    private alertsService: AlertsService,
    private router: Router,
    private nav: NavController,
    private chatService: ChatService,
    private events: EventService
  ) {}

  ionViewWillEnter() {


    this.loadingService.present();
    this.chatService.attemptConnection();
    this.onClearAllLocations();
    this.onClearAllDetails();
    this.selectingSchools = true;
    this.events.connectionEstablishedSource$.subscribe(this.sendReverseAlertMessage.bind(this));
    this.userService.getUser().then(({ uniqueId, agencyId }) => {
      this.schoolService
        .getSchools(uniqueId, agencyId)
        .then((schools: AgencySchool[]) => {
          this.loadingService.dismiss();
          this.schools = schools;

          if(schools === null) {
            return;
          }

          this.setDistricts(schools);
        })
        .catch((err: any) => {
          this.loadingService.dismiss();
        });
    });
  }

  ionViewWillLeave() {
    this.onClearAllDetails();
  }

  sendReverseAlertMessage() {
    if (!this.startAlert) {
      return;
    }

    this.chatService.initReverseAlertMessage(this.reverseAlert);

    this.startAlert = false;
    this.reverseAlert.subject = '';
    this.reverseAlert.message = '';
    this.reverseAlert.schoolIds = [];
    this.reverseAlert.schoolDistrictIds = [];
    this.router.navigate(['tabs/alerts/' + this.reverseAlert.alertId]).then(() => (this.selectingSchools = true));
  }

  setDistricts(schools: AgencySchool[]) {
    const schoolDistrictsList = schools.reduce(
      (districtList: SchoolDistrictsList, school: AgencySchool) => {
        // for every school, check to see if the parentSchoolId exists as a key on the schoolDistricts object
        if (!districtList[school.parentSchoolId]) {
          // if the parentSchoolId doesn't exist yet, add it as a key with its name, and add the school to its school list
          districtList[school.parentSchoolId] = {
            districtName: school.parentSchool,
            schools: [school]
          };
          return districtList;
        }
        // if it the key already exists, just add the school to the district's school list
        districtList[school.parentSchoolId].schools.push(school);
        return districtList;
      },
      {} as SchoolDistrictsList
    );

    this.schoolDistricts = Object.keys(schoolDistrictsList).map((schoolDistrictId: string) => {
      return {
        districtId: parseInt(schoolDistrictId, 10),
        districtName: schoolDistrictsList[schoolDistrictId].districtName,
        schools: schoolDistrictsList[schoolDistrictId].schools,
        selected: false
      };
    });
  }

  onSelectSchool(details: { schoolId: number; districtId: number; checked: boolean }): void {
    const selectedSchool = this.schools.filter((school: AgencySchool) => school.schoolId === details.schoolId)[0];
    selectedSchool.selected = details.checked;

    const selectedDistrict = this.schoolDistricts.filter(
      (schoolDistrict: SchoolDistrict) => schoolDistrict.districtId === details.districtId
    )[0];

    if (selectedDistrict.schools.every((school: AgencySchool) => school.selected)) {
      selectedDistrict.selected = true;
    } else if (selectedDistrict.schools.every((school: AgencySchool) => !school.selected)) {
      selectedDistrict.selected = false;
    }

    if (this.schools.every((school: AgencySchool) => school.selected)) {
      this.allSchoolsSelected = true;
    } else if (this.schools.every((school: AgencySchool) => !school.selected)) {
      this.allSchoolsSelected = false;
    }
  }

  onSelectAllSchools(checked: boolean): void {
    this.schoolDistricts.forEach((schoolDistrict: SchoolDistrict) => (schoolDistrict.selected = checked));
    this.schools.forEach((school: AgencySchool) => (school.selected = checked));
  }

  onSelectDistrict(details: { districtId: number; checked: boolean }): void {
    this.schoolDistricts.forEach((schoolDistrict: SchoolDistrict) => {
      if (schoolDistrict.districtId === details.districtId) {
        schoolDistrict.schools.forEach((school: AgencySchool) => {
          school.selected = details.checked;
        });
      }
    });
  }

  onSetSelectingSchools(selectingSchools: boolean): void {
    this.selectingSchools = selectingSchools;
  }

  onClearAllLocations(): void {
    this.schoolDistricts.forEach((schoolDistrict: SchoolDistrict) => (schoolDistrict.selected = false));
    this.schools.forEach((school: AgencySchool) => (school.selected = false));
    this.reverseAlert.schoolIds.length = 0;
    this.allSchoolsSelected = false;
  }

  onClearAllDetails(): void {
    this.reverseAlert.subject = '';
    this.reverseAlert.message = '';
  }

  sendReverseAlert() {
    this.reverseAlert.schoolIds = this.schools
      .filter((school: AgencySchool) => school.selected)
      .map((s: AgencySchool) => s.schoolId);

    this.schools.forEach((school: AgencySchool) => {
      if (this.reverseAlert.schoolDistrictIds.indexOf(school.parentSchoolId) < 0) {
        this.reverseAlert.schoolDistrictIds.push(school.parentSchoolId);
      }
    });

    this.alertsService
      .startNewReverseAlert(this.reverseAlert)
      .then((response: any) => {
        this.startAlert = true;
        this.reverseAlert.alertId = response.alertId;
        this.reverseAlert.alertGuid = response.alertGuid;
        this.chatService.setAlertId(response.alertId);

        if (this.chatService.getConnectionStatus() === XMPPConnectionStatus.CONNECTED) {
          this.sendReverseAlertMessage();
        }

        this.loadingService.dismiss();
      })
      .catch((err: any) => {
        this.loadingService.dismiss();
        this.presentError();
      });
  }

  async onPresentConfirmation() {
    const alert = await this.alertController.create({
      message: 'Are you sure you want to send this reverse alert?',
      header: 'Confirmation',
      buttons: [
        'Cancel',
        {
          text: 'Send',
          handler: () => {
            this.loadingService.present();
            this.sendReverseAlert();
          }
        }
      ],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }

  async presentError() {
    const alert = await this.alertController.create({
      message: 'There was a problem sending the reverse alert. Please try again.',
      header: 'Error',
      buttons: ['OK'],
      mode: this.platform.is('ios') ? 'ios' : 'md'
    });

    await alert.present();
  }
}
