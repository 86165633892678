import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Subscription as PubSubSubscription } from '../models/subscription';
import { UserService } from './user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { ResponseSummary } from '../models/responseSummary';
import { UIAlertService } from './uiAlert.service';
import { ChatService } from './chat.service';
import { StorageService } from './storage.service';

const PUB_SUBS = 'pubSubNodeIds';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService implements OnDestroy {
  private subscriptionsSub: Subscription;
  subscriptions: PubSubSubscription[] = [];

  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private userService: UserService,
    private uiAlertService: UIAlertService,
    private chatService: ChatService
  ) {
    this.subscriptionsSub = this.chatService.subscriptionsSubject.subscribe(async subscriptions => {
      this.subscriptions = [...subscriptions];
      await this.subscriptionsUpdated();
      await this.getPubSubs();
    });
  }

  getLocalPubSubs(): Promise<string[]> {
    return this.storage.get(PUB_SUBS).then(pubSubs => {
      return pubSubs || [];
    });
  }

  setPubSubsRaw(pubSubsRaw: any): Promise<void> {
    if (pubSubsRaw) {
      const pubSubs: string[] = pubSubsRaw.map(
        (pubSubRaw): string => {
          return pubSubRaw.PubsNodeId;
        }
      );
      return this.setLocalPubSubs(pubSubs);
    }
  }

  setLocalPubSubs(pubSubs: string[]): Promise<void> {
    return this.storage.set(PUB_SUBS, pubSubs);
  }

  clearLocalPubSubs(): Promise<any> {
    return this.storage.remove(PUB_SUBS);
  }

  async subscriptionsUpdated() {
    const pubSubs = await this.getLocalPubSubs();
    this.subscriptions = this.removeUnusedPubSubs(pubSubs);
    this.subscriptions = [...this.subscriptions, ...this.findMissingPubSubs(pubSubs)];
  }

  getPubSubs(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.userService.getAuthToken().then((token: string) => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        this.http
          .get<{ nodes: string[]; responseSummary: ResponseSummary }>(`${environment.baseURL}/accounts/nodes`, {
            headers
          })
          .subscribe({
            next: async (response: any) => {
              const pubSubs = await this.getLocalPubSubs();
              if (!_.isEqual(pubSubs.sort(), response.nodes.sort())) {
                this.setLocalPubSubs(response.nodes);
                this.subscriptionsUpdated();
              }
              resolve(response.nodes);
            },
            error: (failure: any) => {
              if (failure.status === 401) {
                this.uiAlertService.presentExpiredTokenAlert();
              }
              reject(failure);
            }
          });
      });
    });
  }

  /**
   * @method removeUnusedPubSubs Removes PubSubs no longer used
   */
  removeUnusedPubSubs(pubSubNodes: string[]): PubSubSubscription[] {
    return this.subscriptions.filter(subscription => {
      if (!pubSubNodes.includes(subscription.pubSubNodeId)) {
        this.chatService.unsubscribeFromNode(subscription, true);
        return false;
      }
      return true;
    });
  }

  /**
   * @method findMissingPubSubs Adds Missing PubSubs
   */
  findMissingPubSubs(pubSubNodes: string[]): PubSubSubscription[] {
    const subscriptions: PubSubSubscription[] = [];
    pubSubNodes.forEach(pubSubNodeId => {
      if (!this.isCurrentSessionSubscribed(pubSubNodeId)) {
        subscriptions.push({
          pubSubNodeId,
          jid: this.chatService.getCurrentJabberId(),
          subId: ''
        });
      }
      else {
        // note: I'd call this more of a workaround than a fix, but we aren't resubbing to the nodes after closing/reopening the app, leading to a LOT of chat problems.
        // So, I decided to unsub existing nodes and resub afterwards.
        this.chatService.unsubscribeFromNode(this.subscriptions.find(s => s.pubSubNodeId === pubSubNodeId), true);
      }

      this.chatService.subscribeToNode(pubSubNodeId);
    });
    return subscriptions;
  }

  isCurrentSessionSubscribed(pubSubNodeId: string) {
    return this.subscriptions.find(subscription => {
      return this.chatService.isCurrentSessionSubscribedToNode(subscription, pubSubNodeId);
    });
  }

  unsubscribeFromNodes() {
    this.subscriptions.forEach(subscription => {
      this.chatService.unsubscribeFromNode(subscription);
    });
  }

  ngOnDestroy() {
    this.subscriptionsSub.unsubscribe();
  }
}
