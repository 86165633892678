<ion-grid>
  <ion-row class="ion-align-items-center ion-nowrap">
    <ion-col size="9">
      <label class="item-input-wrapper">
        <label class="item-input-wrapper">
          <ion-textarea
            class="msg-input"
            type="text"
            placeholder="Enter message here"
            (ionInput)="onChange($event)"
            (keyup)="onKeyUp($event)"
            autofocus
            maxlength="1000"
            [value]="messageText"
            [disabled]="alertClosed"
          ></ion-textarea>
        </label>
      </label>
    </ion-col>
    <ion-col size="3">
      <ion-button color="blue" (click)="onSendMessage()" [disabled]="!hasMessageText || alertClosed">
        <ion-icon name="send" color="primary"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
